import { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, ThemeProvider, createTheme } from '@mui/material';
import { Outlet, HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Ukraine } from '../ukraine/ukraine';
import { Notifications } from '../notifications/notifications';
import { Auth } from '../auth/auth';
import { PLC } from '../plc/plc';
import { Forbidden } from '../forbidden/forbidden';
import { NotFound } from '../not-found/not-found';
import { Password } from '../password/password';
import { Root } from '../root/root';
import { useStore } from '../store/store';
import { WiFi } from '../wifi/wifi';

export const App: FC = () => {
  const store = useStore();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const mode = store.theme();
  const palette = { mode };
  const theme = createTheme({ palette });
  const enableMouseEvents = true;

  return (
    <Box className="app">
      <ThemeProvider theme={theme}>
        {loading ? (
          <Box sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            height: '100vh',
            backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#121212',
          }}><CircularProgress /></Box>
        ) : (
          <HashRouter>
            <Routes>
              <Route element={
                <DndProvider backend={TouchBackend} options={{ enableMouseEvents }}>
                  <Outlet />
                  <Notifications />
                  <Ukraine />
                </DndProvider>
              }>
                <Route index element={<Root />} />
                <Route path="password" element={<Password />} />
                <Route path="wifi" element={<WiFi />} />
                <Route path="auth" element={<Auth />} />
                <Route path="plc" element={<PLC />} />
                <Route path='403' element={<Forbidden />} />
                <Route path="404" element={<NotFound />} />
                <Route path="*" element={<Navigate to='/404' />} />
              </Route>
            </Routes>
          </HashRouter>
        )}
      </ThemeProvider>
    </Box>
  );
}
