import { Stack } from '@mui/material';
import { FC, Fragment } from 'react';
import { useStore } from '../store/store';
import { Notification } from '../notification/notification';

export const Notifications: FC = () => {
  const store = useStore();
  const notifications = store.notifications();

  return (
    <Fragment>
      <Stack sx={{
        position: 'fixed',
        zIndex: '2000',
        left: '8px',
        bottom: '8px',
      }} spacing={1}>
        {notifications.map((i, key) => (
          <Notification notificationId={i.id} type={i.type} text={i.text} key={key} />
        ))}
      </Stack>
    </Fragment>
  );
}
