import { useTheme } from '@mui/material';
import { FC } from 'react';
import { ELEMENT_WIDTH, ELEMENT_HEIGHT, DATA } from '../store/store';
import { Label } from '../label/label';

interface Props {
  color: string;
  program?: string;
  id?: string;
}

export const ElementAdd: FC<Props> = (props) => {
  const theme = useTheme();
  
  const width = ELEMENT_WIDTH;
  const height = ELEMENT_HEIGHT;

  const id = props.id;
  const color = props.color;
  const program = props.program;

  if (id && program) {
    return (
      <div style={{
        color,
        position: 'relative',
        fontSize: '12px',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '90px',
          marginTop: '11px',
          marginBottom: '11px',
        }}>
          <div style={{
            width: '100%',
          }}>
            <div style={{
              borderBottom: `2px solid ${color}`,
              width: '100%',
              marginBottom: '16px',
            }}></div>
            <div style={{
              borderBottom: `2px solid ${color}`,
              width: '50%',
              marginLeft: '50%',
              marginBottom: '16px',
            }}></div>
            <div style={{
              borderBottom: `2px solid ${color}`,
              width: '50%',
              marginLeft: '50%',
            }}></div>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
            <div style={{
              position: 'absolute',
              top: '-16px',
            }}>ADD</div>
            <div style={{
              transform: 'scale(0.6)',
              transformOrigin: 'right',
              position: 'absolute',
              top: '19px',
              right: '86px',
            }}>
              <Label program={program} element={id} data={DATA.SRC_A} />
            </div>
            <div style={{
              transform: 'scale(0.6)',
              transformOrigin: 'right',
              position: 'absolute',
              top: '37px',
              right: '86px',
            }}>
              <Label program={program} element={id} data={DATA.SRC_B} />
            </div>
            <div style={{
              transform: 'scale(0.6)',
              transformOrigin: 'left',
              position: 'absolute',
              top: '19px',
              left: '86px',
            }}>
              <Label program={program} element={id} data={DATA.DEST} />
            </div>
            <div style={{
              width: '72px',
              border: `2px solid ${color}`,
            }}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 4px',
              }}>
                <div>EN</div>
                <div style={{ color: theme.palette.text.secondary }}>END</div>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 4px',
              }}>
                <div>IN1</div>
                <div>OUT</div>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 4px',
              }}>
                <div>IN2</div>
              </div>
            </div>
          </div>
          <div style={{
            width: '100%',
            position: 'relative',
          }}>
            <div style={{
              borderBottom: `2px solid ${color}`,
              width: '100%',
              marginBottom: '16px',
            }}></div>
            <div style={{
              borderBottom: `2px solid ${color}`,
              width: '50%',
              marginRight: '50%',
              marginBottom: '16px',
            }}></div>
            <div style={{
              borderBottom: `2px solid ${color}`,
              width: '0%',
              marginRight: '100%',
            }}></div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: `${height}px`,
      width: `${width}px`,
    }}>
      <div style={{
        borderBottom: `2px solid ${color}`,
        width: '100%',
        marginBottom: '1px',
      }}></div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div style={{
          height: '25px',
          width: '25px',
          border: `2px solid ${color}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: '3px',
        }}>
          <p style={{ color }}>+</p>
        </div>
      </div>
      <div style={{
        borderBottom: `2px solid ${color}`,
        width: '100%',
        marginBottom: '1px',
      }}></div>
    </div>
  );
}