import { GetApp } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { FC, useState, MouseEvent } from 'react';
import { useTranslation } from '../translation/translation';
import { jsonTransform, useStore, TIMEOUT, API_URL } from '../store/store';

export const JsonGet: FC = () => {
  const store = useStore();
  const [t] = useTranslation();
  const [disabled, setDisabled] = useState(false);

  const onClick = (ev: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    ev.stopPropagation();

    setDisabled(true);

    const token = store.token();
    const headers = { 'Authorization': `Basic ${token}` };
    const signal = AbortSignal.timeout(TIMEOUT);
    const method = 'GET';
    const url = `${API_URL}/ladder_program`;

    fetch(url, { method, signal, headers })
      .then((i) => {
        if (!i.ok) {
          throw new Error('Houston, we have a problem...');
        }
        return i.json();
      })
      .then((i) => {
        setDisabled(false);
        store.setData(jsonTransform(i));
        store.notification({
          type: 'success',
          text: 'The project configuration successfully transfer.',
        });
      })
      .catch((err) => {
        setDisabled(false);
        store.notification({
          type: 'error',
          text: err.message,
        });
      });
  }

  return (
    <Tooltip title={t('Get JSON')} arrow>
      <Button sx={{ marginLeft: '8px' }} disabled={disabled} onClick={onClick} startIcon={<GetApp color="inherit" fontSize="small" />} color="inherit" variant="outlined">{t('Get JSON')}</Button>
    </Tooltip>
  )
}
