import { AppBar, Box, Button, Dialog, DialogContent, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField, Toolbar, Typography, useTheme } from '@mui/material';
import { FC, useState, ChangeEvent, FormEvent } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff, WifiPassword } from '@mui/icons-material';
import { PaperComponent } from '../paper-component/paper-component';
import { Copyright } from '../copyright/copyright';
import { Translation, useTranslation } from '../translation/translation';
import { Title } from '../title/title';
import { API_URL, KEYS, TIMEOUT, useStore } from '../store/store';

interface Data {
  ssid: string;
  password: string;
}

export const WiFi: FC = () => {
  const [t] = useTranslation();
  const [data, setData] = useState<Partial<Data>>();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const store = useStore();

  const token = store.token();
  if (!token) {
    store.notification({
      type: 'warning',
      text: 'You do not have the necessary permissions to access. Please contact an administrator if you believe this is in error.'
    });
    return <Navigate to="/" />;
  }

  const onClickPassword = () => setShowPassword((show) => !show);

  const onMouseDownPassword = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
  };

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const name = ev.target.name;
    const value = ev.target.value;
    setData({ ...data, [name]: value });
  }

  const onSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.stopPropagation();
    
    const method = 'POST';
    const signal = AbortSignal.timeout(TIMEOUT);
    const headers = { 'Authorization': `Basic ${token}` };
    const url = `${API_URL}/wifi`;
    const wifi = data;
    const body = JSON.stringify({ wifi });

    fetch(url, { method, signal, headers, body })
      .then((i) => {
        if (!i.ok) {
          throw new Error('Houston, we have a problem...');
        }
        return i.json();
      })
      .then((i) => {
        store.notification({
          type: 'success',
          text: 'Congratulations! Your WiFi connection has been successfully updated.'
        });
        store.tokenChange();
        navigate('/');
      })
      .catch((err) => {
        store.notification({
          type: 'error',
          text: err.message,
        });
      });
  }

  return (
    <Paper className="wifi" square elevation={0} sx={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'end',
    }}>
      <Dialog
        hideBackdrop
        disableEnforceFocus
        disableScrollLock
        style={{ pointerEvents: 'none' }}
        PaperProps={{ style: { pointerEvents: 'auto', width: '420px', minHeight: '200px' }, id: KEYS.WIFI }}
        open
        PaperComponent={PaperComponent}
      >
        <DialogContent sx={{ p: 0 }}>
          <AppBar position="fixed" sx={{
            zIndex: theme.zIndex.drawer + 1,
          }}>
            <Toolbar>
              <Title />
              <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', flexGrow: 1, mr: 1 }}>
                <Translation />
              </Box>
            </Toolbar>
          </AppBar>
          <form onSubmit={onSubmit}>
            <Box sx={{ padding: '0 8px' }}>
              <Toolbar />
              <Box sx={{ m: '16px 8px' }}>
                <Typography variant="h4" color="textSecondary">
                  {t('Change WiFi')} <WifiPassword sx={{
                    position: 'relative',
                    top: '-10px',
                  }}/>
                </Typography>
                <Typography sx={{
                  fontSize: '16px',
                }} color="textSecondary">
                  {t('Managing Your Device Connection')}
                </Typography>
              </Box>
              <Box className="MuiDialogContent-collapse">
                <TextField
                  required
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="outlined"
                  name="ssid"
                  label={t('SSID')}
                  value={data?.ssid}
                  onChange={onChange}
                />
                <FormControl
                  required
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="outlined"
                >
                  <InputLabel>{t('Password')}</InputLabel>
                  <OutlinedInput
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={onClickPassword}
                          onMouseDown={onMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t('Password')}
                    value={data?.password}
                    onChange={onChange}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box sx={{
              padding: '8px'
            }}>
              <Button color="inherit">{t('Why do you need it?')}</Button>
              <Button color="inherit" component={Link} to="/">{t("Cancel")}</Button>
              <Button type="submit">{t('Submit')}</Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <Copyright />
    </Paper>
  );
}