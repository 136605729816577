import { FC } from 'react';
import { useDrop } from 'react-dnd';
import { useTheme } from '@mui/material';
import { KEYS, useStore, DragObject, DropResult, WIRE_HEIGHT, WIRE_X, WIRE_WIDTH, WIRE_Y } from '../store/store';

export interface Props {
  rung: string;

  a?: string;
  b?: string;

  x?: number;
  y?: number;

  width?: number;
  height?: number;

  color?: string;
}

export const Wire: FC<Props> = (props) => {
  const store = useStore();
  const theme = useTheme();

  const rung = props.rung;

	const [dropParams, drop] = useDrop<DragObject, unknown, DropResult>(() => ({
      accept: 'box',
      drop: (obj, monitor) => {
        const a = props.a;
        const b = props.b;
        const id = obj.id;
        const type = obj.type;
        store.elementUpsert({ a, b, rung }, { id, type });
      },
      collect: (result) => {
        const isOver = result.isOver();
        const canDrop = result.canDrop();
        return { isOver, canDrop };
      }
  }), [props]);

  const dragging = dropParams.canDrop && dropParams.isOver;

  const color = dragging
    ? theme.palette.success.main
    : theme.palette.primary.main;

  const width = props.width ? props.width - WIRE_X : WIRE_WIDTH;
  const height = props.height ? props.height : WIRE_HEIGHT;

  const left = props?.x || 0;
  const top = props?.y || WIRE_Y;

  return (
    <div ref={drop} onDoubleClick={(ev) => {
      ev.stopPropagation();
      store.dialogChange([KEYS.RUNG_UPDATE, rung]);
    }} style={{
      height: `${height}px`,
      width: `${width}px`,
      top: `${top}px`,
      left: `${left}px`,
      margin: '8px 0',
      position: 'absolute',
      // border: '1px dashed orange',
    }}>
      <div style={{
        width: '100%',
        top: '23px',
        position: 'absolute',
        borderTop: `2px solid ${color}`,
      }}>
        {dragging && <div style={{
          height: '10px',
          width: '10px',
          background: `${color}`,
          position:'absolute',
          left: 'calc(50% - 5px)',
          top: '-5px',
          borderRadius: '50%',
        }}></div>}
      </div>
    </div>
  )
}
