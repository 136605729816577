import { FC } from 'react';
import { useGridApiContext, renderEditInputCell } from '@mui/x-data-grid';
import { VARIABLE_TYPES } from '../store/store';

const EditCell: FC<any> = (props) => {
  const apiRef = useGridApiContext();

  const onValueChange = async (ev: any, str: any) => {
    if (str.includes('.') && props.row.type === VARIABLE_TYPES.DINT) {
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: 'type',
        value: VARIABLE_TYPES.LREAL,
      });
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: 'value',
        value: str,
      });
    }

    if (!str.includes('.') && props.row.type === VARIABLE_TYPES.LREAL) {
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: 'type',
        value: VARIABLE_TYPES.DINT,
      });
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: 'value',
        value: str,
      });
    }
  };

  return <>{renderEditInputCell({ ...props, onValueChange, type: 'number' })}</>
}

export const renderEdit = (params: any) => {
  return <EditCell {...params} />
};

