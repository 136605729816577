import { FC } from 'react';
import { Box } from '@mui/material';
import { Rail } from '../rail/rail';

interface Props {
  width: number;
  height: number;
  children: JSX.Element;
}

export const Rails: FC<Props> = (props) => {
  const width = props.width;
  const height = props.height;
  const children = props.children;

  return (
    <Box sx={{ position: 'absolute', marginRight: '8px' }}>
      <Rail width={width} height={height}>{children}</Rail>
    </Box>
  )
}