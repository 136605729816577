import { ClickAwayListener, IconButton, Paper, Popper, Tooltip, useTheme } from '@mui/material';
import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';
import { FC, createElement, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { KEYS, useStore, DragObject, DropResult, PROGRAM_ELEMENTS, ELEMENT_TYPES, Position, find } from '../store/store';
import { ElementRemove } from '../element-remove/element-remove';
import { ElementUpdate } from '../element-update/element-update';

interface Props {
  id: string;
  type: ELEMENT_TYPES;
  color: string;
  program: string;
  x?: number;
  y?: number;
}

export const Element: FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const [position, setPosition] = useState<Position>();

  const store = useStore();
  const theme = useTheme();

  const item = find(props.type);

  const id = props.id;
  const type = props.type;
  const program = props.program;

  const name = item ? item.name : props.type;

  const [dragParams, drag] = useDrag(() => ({
    type: 'box',
    item: { type, name, id },
    collect: (monitor: any) => ({
      dragging: monitor.isDragging(),
    }),
  }), [props]);

  const [dropParams, drop] = useDrop<DragObject, unknown, DropResult>(() => ({
    accept: 'box',
    drop: (obj, monitor) => {
      const id = obj.id;
      const type = obj.type;
      const relation = props.id;
      store.elementInsert(relation, { id, type });
    },
    collect: (monitor) => {
      const isOver = monitor.isOver();
      const canDrop = monitor.canDrop();
      return { isOver, canDrop };
    }
  }), [props]);

  const dragging = dragParams.dragging;
  const color = dropParams.canDrop && dropParams.isOver
    ? theme.palette.success.main
    : props.color;

  const x = props.x;
  const y = props.y;

  return (
    <div ref={drag} style={{
      cursor: dragging ? 'grabbing' : 'grab',
      left: `${x}px`,
      top: `${y}px`,
      position: 'absolute',
      // border: '1px dashed purple',
    }}>
      <Tooltip title={name} arrow placement="top">
        <div ref={drop} contextMenu="none" onDoubleClick={(ev) => {
          ev.stopPropagation();
          store.dialogChange([KEYS.ELEMENT_UPDATE, id]);
        }} onClick={(ev) => {
          ev.stopPropagation();
          // console.log('element click');
        }} onContextMenu={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          setPosition({x: ev.clientX, y:  ev.clientY});
          setAnchorEl(ev.currentTarget);
        }}>
          <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={(ev) => {
            ev.stopPropagation();
            setAnchorEl(undefined);
          }}>
            <Popper open={!!anchorEl} anchorEl={anchorEl} sx={{ transform: `translate3d(${position?.x}px, ${position?.y}px, 0px) !important` }}>
              <Paper sx={{ p: 1 }}>
                <IconButton size="small" onClick={(ev) => {
                  ev.stopPropagation();
                  store.dialogChange([KEYS.ELEMENT_UPDATE, id]);
                }}>
                  <EditOutlined color={store.dialog([KEYS.ELEMENT_UPDATE, id]) ? 'primary' : 'inherit'} fontSize="small" />
                </IconButton>

                <IconButton size="small" onClick={(ev) => {
                  ev.stopPropagation();
                  store.dialogChange([KEYS.ELEMENT_REMOVE, id]);
                }}>
                  <DeleteOutlineOutlined color={store.dialog([KEYS.ELEMENT_REMOVE, id]) ? 'primary' : 'inherit'} fontSize="small" />
                </IconButton>
              </Paper>
            </Popper>
          </ClickAwayListener>
          {createElement(PROGRAM_ELEMENTS[type], { color, id, program })}
        </div>
      </Tooltip>

      <ElementUpdate id={id} program={program} />
      <ElementRemove id={id} />
    </div>
  );
}
