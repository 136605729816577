import { ArrowDropDown, ArrowDropUp, FolderOpenOutlined, FolderOutlined } from '@mui/icons-material';
import { Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { FC, Fragment } from 'react';
import { Instruction } from '../instruction/instruction';
import { useTranslation } from '../translation/translation';
import { KEYS, useStore, instructions } from '../store/store';

export const Instructions: FC = () => {
  const store = useStore();
  const [t] = useTranslation();
  const drawer = store.drawer();
  return (
    <Fragment>
      <List sx={{ padding: '0px' }}>
        <ListItem disablePadding secondaryAction={drawer && (
            <IconButton sx={{
              display: drawer ? 'initial' : 'none',
            }} size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.collapseChange( KEYS.INSTRUCTIONS);
              }}>
              {!store.collapse(KEYS.INSTRUCTIONS)
                ? <ArrowDropDown fontSize="small" />
                : <ArrowDropUp fontSize="small" />}
            </IconButton>
          )}>
          <ListItemButton onClick={(ev) => {
            ev.stopPropagation();
            store.collapseChange(KEYS.INSTRUCTIONS);
          }} sx={{ padding: '8px 15px' }}>
            <Tooltip title={t('Instructions')} arrow>
              <ListItemIcon>
                {!store.collapse(KEYS.INSTRUCTIONS)
                  ? <FolderOpenOutlined color="primary" fontSize="small" />
                  : <FolderOutlined color="primary" fontSize="small" />}
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t('Instructions')} primaryTypographyProps={{ color: 'primary', variant: 'button' }} />
          </ListItemButton>
        </ListItem>
        <Collapse in={!store.collapse(KEYS.INSTRUCTIONS)} timeout="auto">
          <List component="div" disablePadding>
            {instructions.map((m, key) => {
              return (
                <Fragment key={key}>
                  <ListItem disablePadding secondaryAction={
                    <IconButton sx={{
                      display: drawer ? 'initial' : 'none',
                    }} size="small" onClick={(ev) => {
                      ev.stopPropagation();
                      store.collapseChange([KEYS.INSTRUCTION, m.name]);
                    }}>
                      {!store.collapse([KEYS.INSTRUCTION, m.name])
                        ? <ArrowDropDown fontSize="small" />
                        : <ArrowDropUp fontSize="small" />}
                    </IconButton>
                  }>
                    <ListItemButton onClick={(ev) => {
                      ev.stopPropagation();
                      store.collapseChange([KEYS.INSTRUCTION, m.name]);
                    }} sx={{ padding: '8px 15px' }}>
                      <Tooltip title={t(m.name)} arrow>
                        <ListItemIcon>
                          {!store.collapse([KEYS.INSTRUCTION, m.name])
                            ? <FolderOpenOutlined fontSize="small" />
                            : <FolderOutlined fontSize="small" />}
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t(m.name)} primaryTypographyProps={{ variant: 'button' }} />
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={!store.collapse([KEYS.INSTRUCTION, m.name])} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {m.items.map((i, key) => (
                        <Fragment key={key}>
                          <Instruction
                            name={i.name}
                            type={i.type}
                          />
                        </Fragment>
                      ))}
                    </List>
                  </Collapse>
                </Fragment>
              );
            })}
          </List>
        </Collapse>
      </List>
      {/* <InstructionCreate /> */}
    </Fragment>
  );
}
