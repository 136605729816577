import { useTheme } from '@mui/material';
import { FC } from 'react';
import { ELEMENT_HEIGHT, ELEMENT_WIDTH, DATA } from '../store/store';
import { Label } from '../label/label';

interface Props {
  color: string;
  program?: string;
  id?: string;
}

export const ElementTres: FC<Props> = (props) => {
  const theme = useTheme();

  const width = ELEMENT_WIDTH;
  const height = ELEMENT_HEIGHT;

  const id = props.id;
  const color = props.color;
  const program = props.program;

  if (id && program) {
    return (
      <div style={{
        color,
        position: 'relative',
        fontSize: '12px',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: `${height}px`,
          width: `${width}px`,
        }}>
          <div style={{
            position: 'absolute',
            top: '-5px',
          }}>TRES</div>
          <div style={{
            transform: 'scale(0.6)',
            transformOrigin: 'left',
            position: 'absolute',
            top: '-6px',
            left: '40px',
          }}>
            <Label program={program} element={id} data={DATA.NUM} />
          </div>
          <div style={{
            borderBottom: `2px solid ${color}`,
            width: '100%',
            marginBottom: '1px',
          }}></div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <div style={{
              height: '25px',
              width: '25px',
              borderRadius: '50%',
              backgroundColor: color,
              border: `2px solid ${color}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <p style={{
                color: theme.palette.mode === 'light' ? '#ffffff' : '#121212',
              }}>R</p>
            </div>
          </div>
          <div style={{ 
            borderBottom: `2px solid ${color}`,
            width: '100%',
            marginBottom: '1px',
          }}></div>
        </div>
      </div>
    );
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: `${height}px`,
      width: `${width}px`,
    }}>
      <div style={{
        borderBottom: `2px solid ${color}`,
        width: '100%',
        marginBottom: '1px',
      }}></div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div style={{
          height: '25px',
          width: '25px',
          borderRadius: '50%',
          backgroundColor: color,
          border: `2px solid ${color}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <p style={{
            color: theme.palette.mode === 'light' ? '#ffffff' : '#121212',
          }}>R</p>
        </div>
      </div>
      <div style={{ 
        borderBottom: `2px solid ${color}`,
        width: '100%',
        marginBottom: '1px',
      }}></div>
    </div>
  );
}