import { FC, Fragment } from 'react';
import { useTheme } from '@mui/material';
import { createElement } from 'react';
import { Wire } from '../wire/wire';
import { Element } from '../element/element';
import { WIRE_HEIGHT, TYPES } from '../store/store';

interface Props {
  program: string;
  data: any[],
}

export const Draw: FC<Props> = (props) => {
  const theme = useTheme();

  const data = props.data;
  const program = props.program;
  const color = theme.palette.primary.main;

  return (
    <>
      {data?.map((i, key) => {
        const x = i.x;
        const y = i.y;

        const width = i.width;
        const height = i.height;

        if (i.type === TYPES.ELEMENT) {
          const id = i.id;
          const type = i.data;
          return (
            <Fragment key={key}>
              <Element id={id} program={program} type={type} color={color} x={x} y={y} />
            </Fragment>
          );
        }

        if (i.type === TYPES.WIRE) {
          const rung = i.rung;
          const [a, b] = i.data;
          return (
            <Fragment key={key}>
              <Wire rung={rung} x={x} y={y} width={width} height={height} a={a} b={b} />
            </Fragment>
          );
        }

        const [a, b] = i.data;
        return (
          <div key={key} style={{
            left: `${x}px`,
            top: `${y}px`,
            width: `${width}px`,
            height: `${height}px`,
            // border: '1px dashed red',
            position: 'absolute',
          }}>
            {/* <span style={{
              left: `0px`,
              top: `0px`,
              position: 'absolute',
            }}>{i.id}</span> */}
            <div style={{
              left: '0px',
              top: `${WIRE_HEIGHT / 2-0.5}px`,
              width: `${width}px`,
              height: `${height-i.offset}px`,
              borderLeft: `2px solid ${color}`,
              position: 'absolute',
              zIndex: -1,
            }}></div>
              {createElement(Draw, { data: a, program })}
              {createElement(Draw, { data: b, program })}
            <div style={{
              left: '0px',
              top: `${WIRE_HEIGHT / 2-0.5}px`,
              width: `${width}px`,
              height: `${height-i.offset}px`,
              borderRight: `2px solid ${color}`,
              position: 'absolute',
              zIndex: -1,
            }}></div>
          </div>
        )
      })}
    </>
  )
}
