import { FC, useState, ChangeEvent, useEffect, FormEvent } from 'react';
import { IconButton, Button, TextField, List, ListItem, ListItemText, Box, Collapse, CircularProgress, FormControl, InputLabel, MenuItem, Select, Autocomplete, Chip } from '@mui/material';
import { ArrowDropDown, ArrowDropUp, Close } from '@mui/icons-material';
import { useTranslation } from '../translation/translation';
import { Modal } from '../modal/modal';
import { cRecursion, DATA, Element, ELEMENT_TYPES, KEYS, tRecursion, useStore, VARIABLES, VARIABLE_TYPES, find, instruction } from '../store/store';

interface Props {
  id: string;
  program: string;
}

export const ElementUpdate: FC<Props> = (props) => {
  const store = useStore();
  const [t] = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Partial<Element>>();

  const id = props.id;
  const program = props.program;
  const element = store.element(id);

  useEffect(() => {
    setData(element);
    setLoading(false);
  }, [element]);

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const name = ev.target.name;
    const value = ev.target.value;
    setData({ ...data, [name]: value });
  }

  const onSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (!data) {
      store.notification({
        type: 'error',
        text: 'Houston, we have a problem...',
      });
      return;
    }
    store.elementUpdate(id, {
      type: data.type,
      name: data.name,
      data: data.data,
      description: data.description,
    });

    store.dialogChange([KEYS.ELEMENT_UPDATE, id]);
  }

  const item = instruction(element.type);
  const p = store.program(program);

  const parameters = store.parameter(element.type);
  const tags = store.tags();
  const elements = p.rungs.flat().map((rung) => rung.elements).flat();
  const timers: any[] = tRecursion(elements);
  const counters: any[] = cRecursion(elements);

  const v = p.variables.reduce((accumulator, variable) => {
    const type = variable.type.toUpperCase();
    if (!accumulator[type]) {
      accumulator[type] = [];
    }
    accumulator[type].push(variable);
    return accumulator;
  }, {} as any);

  return (
    <Modal
      style={{ pointerEvents: 'auto', maxWidth: '320px' }}
      open={!!store.dialog([KEYS.ELEMENT_UPDATE, id])}
      id={[KEYS.ELEMENT_UPDATE, id].join('-')}
    >
      <List sx={{ padding: '0px' }}>
        <ListItem sx={{ padding: '8px 8px 8px 12px' }}>
          <ListItemText primary={t('Update Element')} primaryTypographyProps={{ color: 'primary', variant: 'button' }} />
          <Box>
            <IconButton size="small" onClick={(ev) => {
              ev.stopPropagation();
              store.collapseChange([KEYS.ELEMENT_UPDATE, id]);
            }}>
              {!store.collapse([KEYS.ELEMENT_UPDATE, id])
                ? <ArrowDropDown fontSize="small" />
                : <ArrowDropUp fontSize="small" />}
            </IconButton>
            <IconButton size="small" onClick={(ev) => {
              ev.stopPropagation();
              store.dialogChange([KEYS.ELEMENT_UPDATE, id]);
            }}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
        </ListItem>
        <Collapse className="MuiDialogContent-collapse" in={!store.collapse([KEYS.ELEMENT_UPDATE, id])} timeout="auto">
          {loading ? (
            <CircularProgress />
          ) : (
            <form onSubmit={onSubmit}>
              <Box sx={{ padding: '0 8px' }}>
                <FormControl variant="outlined" size="small" sx={{ minWidth: '100%' }}>
                  <InputLabel>{t('Type')}</InputLabel>
                  <Select
                    size="small"
                    value={data?.type}
                    MenuProps={{
                      style: {
                        zIndex: 20000
                      }
                    }}
                    label={t('Type')}
                    onChange={(ev) => {
                      ev.stopPropagation();
                      const type = ev.target.value as ELEMENT_TYPES;
                      const element = find(type);

                      const name = element ? element.name : '';
                      const description = element ? element.description : '';
                      setData({ ...data, name, type, description });
                    }}
                  >
                    {item.items.map((i: any, key: number) => (
                      <MenuItem key={key} value={i.type}>{i.type}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  required
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="outlined"
                  name="name"
                  label={t('Name')}
                  value={data?.name}
                  onChange={onChange}
                />
                <TextField
                  fullWidth
                  multiline
                  size="small"
                  margin="dense"
                  variant="outlined"
                  name="description"
                  label={t('Description')}
                  value={data?.description}
                  onChange={onChange}
                />
                {Object.keys(parameters).map((i, key) => {
                  const name = i as DATA;
                  const variables = parameters[name] as VARIABLES[];
                  const options: any[] = [];

                  Array.from(Array(6).keys()).forEach((_, i) => {
                    if (variables.includes(VARIABLES.I)) {
                      options.push({ label: `I${i}`, id: `I${i}` });
                    }
                    if (variables.includes(VARIABLES.AI)) {
                      options.push({ label: `AI${i}`, id: `AI${i}` });
                    }
                    if (variables.includes(VARIABLES.Q)) {
                      options.push({ label: `Q${i}`, id: `Q${i}` });
                    }
                    if (variables.includes(VARIABLES.AQ)) {
                      options.push({ label: `AQ${i}`, id: `AQ${i}` });
                    }
                  });

                  tags.filter((i) => i.address).forEach((tag) => {
                    if (
                      tag.address.includes(VARIABLES.I) ||
                      tag.address.includes(VARIABLES.AI) ||
                      tag.address.includes(VARIABLES.Q) ||
                      tag.address.includes(VARIABLES.AQ) ||
                      tag.address.includes(VARIABLES.M) ||
                      tag.address.includes(VARIABLES.T)
                    ) {
                      options.push({ label: tag.name || tag.address, id: tag.address });
                    }
                  });

                  v[VARIABLE_TYPES.BOOL]?.forEach((variable: any, i: number) => {
                    if (variables.includes(VARIABLES.M)) {
                      options.push({ label: variable.name || `M${i}`, id: `M${i}` });
                    }
                  });
            
                  v[VARIABLE_TYPES.DINT]?.forEach((variable: any, i: number) => {
                    if (variables.includes(VARIABLES.MW)) {
                      options.push({ label: variable.name || `MW${i}`, id: `MW${i}` });
                    }
                  });
            
                  v[VARIABLE_TYPES.LREAL]?.forEach((variable: any, i: number) => {
                    if (variables.includes(VARIABLES.MF)) {
                      options.push({ label: variable.name || `MF${i}`, id: `MF${i}` });
                    }
                  });

                  if (
                    [
                      ELEMENT_TYPES.TON,
                      ELEMENT_TYPES.TOF,
                      ELEMENT_TYPES.TRES,
                    ].includes(element.type) && name !== DATA.PRESET
                  ) {
                    timers.forEach((_, i) => {
                      options.push({ label: `T${i}`, id: `T${i}` });
                    })
                  }
      
                  if (
                    [
                      ELEMENT_TYPES.CNTU,
                      ELEMENT_TYPES.CNTD,
                      ELEMENT_TYPES.CNTR,
                    ].includes(element.type) && name !== DATA.PRESET
                  ) {
                    counters.forEach((_, i) => {
                      options.push({ label: `C${i}`, id: `C${i}` });
                    })
                  }
      
                  if (
                    [
                      ELEMENT_TYPES.ADD,
                      ELEMENT_TYPES.SUB,
                      ELEMENT_TYPES.MUL,
                      ELEMENT_TYPES.DIV,
                      ELEMENT_TYPES.MOV,
                      ELEMENT_TYPES.EQ,
                      ELEMENT_TYPES.NQ,
                      ELEMENT_TYPES.GT,
                      ELEMENT_TYPES.LT,
                      ELEMENT_TYPES.GE,
                      ELEMENT_TYPES.LE,
                    ].includes(element.type)
                  ) {
                    timers.forEach((_, i) => {
                      options.push({ label: `T${i}:ET`, id: `T${i}:ET` });
                    })
                    counters.forEach((_, i) => {
                      options.push({ label: `C${i}:CV`, id: `C${i}:CV` });
                    })
                  }
                  const value = (element.data && element.data[name]) || null;
                  const option = options.filter((option) => option.id === value);
                  if (value && !option.length) {
                    options.push({
                      id: value,
                      label: value,
                    })
                  }
                  const defaultValue = element.data && element.data[name]
                    ? options.filter((option) => option.id === value)
                    : [];
                  return (
                    <Autocomplete key={key}
                      size="small"
                      multiple
                      autoSelect
                      options={options}
                      defaultValue={defaultValue}
                      onChange={(ev, items) => {
                        ev.stopPropagation();
                        if (items.length > 1) {
                          items.shift(); 
                        }
                        const value = items[0];
                        setData({ ...data, data: { ...(data ? data.data : {}), [name]: value?.id || value } });
                      }}
                      slotProps={{
                        popper: {
                          sx: {
                            zIndex: 20000
                          }
                        }
                      }}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          label={`${name.toUpperCase()} Parameter`}
                        />
                      }
                      freeSolo
                      getOptionLabel={(option) => option?.label}
                      isOptionEqualToValue={(option, value) => option?.id === value}
                      renderTags={(values, getTagProps) => {
                        return values.map((option, index) => (
                          <Chip
                            variant="outlined"
                            size="small"
                            label={option?.label || option}
                            {...getTagProps({ index })}
                          />
                        ));
                      }}
                    />
                  )
                })}
              </Box>
              <Box sx={{
                padding: '8px'
              }}>
                <Button color="inherit" onClick={(ev) => {
                  ev.stopPropagation();
                  store.dialogChange([KEYS.ELEMENT_UPDATE, id]);
                }}>{t('Cancel')}</Button>
                <Button type="submit">{t('Confirm')}</Button>
              </Box>
            </form>
          )}
        </Collapse>
      </List>
    </Modal> 
  );
}
