import { FC } from 'react';
import { Drawer, AppBar, Box, Toolbar, CssBaseline, useTheme, Divider } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { ProgramPreview } from '../program-preview/program-preview';
import { Instructions } from '../instructions/instructions';
import { Program } from '../program/program';
import { Copyright } from '../copyright/copyright';
import { Title } from '../title/title';
import { Examples } from '../examples/examples';
import { Translation } from '../translation/translation';
import { useStore } from '../store/store';
import { Programs } from '../programs/programs';
import { Logout } from '../logout/logout';
import { JsonGet } from '../json-get/json-get';
import { JsonSet } from '../json-set/json-set';

export const PLC: FC = () => {
  const store = useStore();
  const theme = useTheme();
  const token = store.token();

  if (!token) {
    store.notification({
      type: 'warning',
      text: 'You do not have the necessary permissions to access. Please contact an administrator if you believe this is in error.'
    });
    return <Navigate to="/" />;
  }

  const drawer = store.drawer();
  return (
    <Box className="plc" sx={{
      display: 'flex'
    }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{
        zIndex: theme.zIndex.drawer + 1,
      }}>
        <Toolbar>
          <Title />
          <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', flexGrow: 1, mr: 1 }}>
            <JsonGet />
            <JsonSet />
            <Logout />
            <Translation />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: drawer ? 320 : 49.3,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          overflowX: 'hidden',
          '& .MuiDrawer-paper': {
            width: drawer ? 320 : 49.3,
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}>
        <Toolbar />
        <Instructions />
        <Divider />
        <Examples />
        <Divider />
        <Programs />        
      </Drawer>
      <Box sx={{
        flexGrow: 1,
        padding: 0,
      }}>
        <Box>
          <Box sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
            <Box>
              <Toolbar />
              <Program />
              <ProgramPreview />
            </Box>
          </Box>
        </Box>
        <Copyright />
      </Box>
    </Box>
  );
}
