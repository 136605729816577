import { CSSProperties, FC, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { PaperComponent } from '../paper-component/paper-component';

interface Props {
  children: JSX.Element;
  style: CSSProperties;
  open: boolean;
  id: string;
}

export const Modal: FC<Props> = (props) => {
  const [zIndex, setZIndex] = useState(1300);
  const id = props.id;
  const open = props.open;
  const style = props.style;
  const children = props.children;

  return (
    <Dialog
      hideBackdrop
      disableEnforceFocus
      disableScrollLock
      className="dialog"
      style={{ pointerEvents: 'none', zIndex }}
      PaperProps={{ style, id }}
      open={open}
      PaperComponent={PaperComponent}
      onMouseDown={(ev) => {
        const i = Array.from(document.querySelectorAll('.dialog')).map((e: any) => {
          return Number(e.style.zIndex) || 1300;
        });
        setZIndex(Math.max(...i)+1);
      }}
    >
      <DialogContent sx={{ p: 0, height: '100%', overflowY: 'hidden' }}>
        {children}
      </DialogContent>
    </Dialog>
  )
}
