import { FC, Fragment } from 'react';
import { Box } from '@mui/material';
import { useStore } from '../store/store';
import { Rung } from '../rung/rung';
import { RungUpdate } from '../rung-update/rung-update';
import { RungRemove } from '../rung-remove/rung-remove';
import { RungCreate } from '../rung-create/rung-create';

interface Props {
  program: string;
}

export const Rungs: FC<Props> = (props) => {
  const store = useStore();

  const program = props.program;
  const rungs = store.rungs(program);

  return (
    <Box sx={{ marginBottom: '8px' }}>
      {rungs.map((i, key) => (
        <Fragment key={key}>
          <Rung program={program} id={i.id} />
        </Fragment>
      ))}
      {rungs.map((i, key) => (
        <Fragment key={key}>
          <RungUpdate id={i.id} />
          <RungRemove id={i.id} />
        </Fragment>
      ))}
      <RungCreate program={program} />
    </Box>
  )
}
