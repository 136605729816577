import { FC, useState } from 'react';
import { Box, ClickAwayListener, IconButton, Paper, Popper } from '@mui/material';
import { Add, DeleteOutlineOutlined, EditOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useResizeDetector } from 'react-resize-detector';
import { Rails } from '../rails/rails';
import { Draw } from '../draw/draw';
import { useStore, transform, transformSize, Position, KEYS } from '../store/store';

interface Props {
  program: string;
  id: string;
}

export const Rung: FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const [position, setPosition] = useState<Position>();

  const store = useStore();

  const { ref, width, height } = useResizeDetector();

  const id = props.id;
  const program = props.program;

  const rungs = store.rungs(program);
  const rung = store.rung(id);

  const data = transform(id, rung.elements, { width, height });
  const size = transformSize(data, { width: 0, height: 0 });

  const index = rungs.findIndex((i) => i.id === id);

  return (
    <Box className="rung" sx={{ m: '0px 8px' }} contextMenu="none" onContextMenu={(ev) => {
      ev.preventDefault();
      setPosition({x: ev.clientX, y:  ev.clientY});
      setAnchorEl(ev.currentTarget);
    }}>
      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={(ev) => {
        ev.stopPropagation();
        setAnchorEl(undefined);
      }}>
        <Popper open={!!anchorEl} anchorEl={anchorEl} sx={{ transform: `translate3d(${position?.x}px, ${position?.y}px, 0px) !important` }}>
          <Paper sx={{ p: 1 }}>
            {index === rungs.length-1 && (<IconButton size="small" onClick={(ev) => {
              ev.stopPropagation();
              store.dialogChange(KEYS.RUNG_CREATE);
            }}>
              <Add fontSize="small" />
            </IconButton>)}
            <IconButton size="small" disabled={index === rungs.length-1} onClick={(ev) => {
              ev.stopPropagation();
              store.rungDown(program, id);
            }}>
              <KeyboardArrowDown fontSize="small" />
            </IconButton>
            <IconButton size="small" disabled={!index} onClick={(ev) => {
              ev.stopPropagation();
              store.rungUp(program, id);
            }}>
              <KeyboardArrowUp fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={(ev) => {
              ev.stopPropagation();
              store.dialogChange([KEYS.RUNG_UPDATE, id]);
            }}>
              <EditOutlined color={store.dialog([KEYS.RUNG_UPDATE, id]) ? 'primary' : 'inherit'} fontSize="small" />
            </IconButton>
            <IconButton size="small" disabled={!index} onClick={(ev) => {
              ev.stopPropagation();
              store.dialogChange([KEYS.RUNG_REMOVE, id]);
            }}>
              <DeleteOutlineOutlined color={store.dialog([KEYS.RUNG_REMOVE, id]) ? 'primary' : 'inherit'} fontSize="small" />
            </IconButton>
          </Paper>
        </Popper>
      </ClickAwayListener>
      <Box ref={ref} sx={{
        position: 'relative',
        width: '100%',
        height: `${size.height}px`,
        clip: 'rect(0, 100%, auto, 0)'
      }}>
        <Rails width={size.width} height={size.height}>
          <Draw program={program} data={data} />
        </Rails>
      </Box>
    </Box>
  )
}
