import { Close } from '@mui/icons-material';
import { Alert, AlertColor, IconButton, useTheme } from '@mui/material';
import { FC, MouseEvent, useEffect } from 'react';
import { useStore } from '../store/store';
import { useTranslation } from '../translation/translation';

interface Props {
  notificationId: string;
  text: string;
  type: AlertColor;
}

export const Notification: FC<Props> = (props) => {
  const [t] = useTranslation();
  const store = useStore();
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      store.notificationRemove(props.notificationId)
    }, 5000);
  // eslint-disable-next-line
  }, []);

  const onClick = (ev: MouseEvent<HTMLButtonElement>) => {
    const id = ev.currentTarget.getAttribute('data-id');
    if (!id) {
       throw new Error('...'); 
    }
    store.notificationRemove(id);
  };

  return (
    <Alert variant="filled" action={
      <IconButton color="inherit" size="small" data-id={props.notificationId} onClick={onClick}>
        <Close fontSize="small" />
      </IconButton>
    } sx={{
      width: '350px',
      whiteSpace: 'normal',
      '& a': {
        color: theme.palette.info.contrastText
      }
    }} severity={props.type}>
      {t(props.text)}
    </Alert>
  );
}
