import { FC } from 'react';
import { Box, useTheme } from '@mui/material';

interface Props {
  width: number;
  height: number;
  children: JSX.Element;
}

export const Rail: FC<Props> = (props) => {
  const theme = useTheme();

  const width = props.width;
  const height = props.height;
  const children = props.children;

  const color = theme.palette.primary.main;

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      height: `${height}px`,
      width: `${width}px`,
      borderLeft: `2px solid ${color}`,
      borderRight: `2px solid ${color}`,
    }}>{children}</Box>
  )
}