import { GetApp } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC, MouseEvent, useState, useEffect } from 'react';
import { json, useStore } from '../store/store';

export const JsonFileGet: FC = () => {
  const store = useStore();
  const [value, setValue] = useState<object>();
  const [disabled, setDisabled] = useState(true);

  const data = store.getData();
  useEffect(() => {
    setValue(json(data));
    setDisabled(false);
  }, [data]);
  
  const onClick = (ev: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    ev.stopPropagation();

    const json = JSON.stringify(value, null, 4);
    const blob = new Blob([json],{ type:'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = href;
    link.download = `${Date.now()}-plc.json`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <IconButton size="small" disabled={disabled} onClick={onClick}>
      <GetApp fontSize="small" />
    </IconButton>
  )
}