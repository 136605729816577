import { FC, useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { IconButton, Button, TextField, List, ListItem, ListItemText, Box, Collapse } from '@mui/material';
import { ArrowDropDown, ArrowDropUp, Close } from '@mui/icons-material';
import { useTranslation } from '../translation/translation';
import { KEYS, Program, useStore } from '../store/store';
import { Modal } from '../modal/modal';

interface Props {
  program: string;
}

export const RungCreate: FC<Props> = (props) => {
  const store = useStore();
  const [t] = useTranslation();
  const [data, setData] = useState<Partial<Program>>();

  const program = props.program;
  const rungs = store.rungs(program);

  useEffect(() => {
    setData({
      name: `Rung ${rungs.length+1}`,
    });
  }, [rungs])

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const name = ev.target.name;
    const value = ev.target.value;
    setData({ ...data, [name]: value });
  }

  const onSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (!data || !data.name) {
      store.notification({
        type: 'error',
        text: 'Houston, we have a problem...',
      });
      return;
    }
    store.rungCreate(program, {
      name: data.name,
      description: data.description,
    });

    store.dialogChange(KEYS.RUNG_CREATE);
  }

  return (
    <Modal
      style={{ pointerEvents: 'auto', maxWidth: '320px' }}
      id={KEYS.RUNG_CREATE}
      open={!!store.dialog(KEYS.RUNG_CREATE)}
    >
      <List sx={{ padding: '0px' }}>
        <ListItem sx={{ padding: '8px 8px 8px 12px' }}>
          <ListItemText primary={t('Create Rung')} primaryTypographyProps={{ color: 'primary', variant: 'button' }} />
          <Box>
            <IconButton size="small" onClick={(ev) => {
              ev.stopPropagation();
              store.collapseChange(KEYS.RUNG_CREATE);
            }}>
              {!store.collapse(KEYS.RUNG_CREATE)
                ? <ArrowDropDown fontSize="small" />
                : <ArrowDropUp fontSize="small" />}
            </IconButton>
            <IconButton size="small" onClick={(ev) => {
              ev.stopPropagation();
              store.dialogChange(KEYS.RUNG_CREATE);
            }}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
        </ListItem>
        <Collapse className="MuiDialogContent-collapse" in={!store.collapse(KEYS.RUNG_CREATE)} timeout="auto">
          <form onSubmit={onSubmit}>
            <Box sx={{ padding: '0 8px' }}>
              <TextField
                required
                fullWidth
                size="small"
                margin="dense"
                variant="outlined"
                name="name"
                label={t('Name')}
                value={data?.name || ''}
                onChange={onChange}
              />
              <TextField
                fullWidth
                multiline
                size="small"
                margin="dense"
                variant="outlined"
                name="description"
                label={t('Description')}
                value={data?.description || ''}
                onChange={onChange}
              />
              </Box>
              <Box sx={{
                padding: '8px'
              }}>
                <Button color="inherit" onClick={(ev) => {
                  ev.stopPropagation();
                  store.dialogChange(KEYS.RUNG_CREATE);
                }}>Cancel</Button>
                <Button type="submit">Confirm</Button>
            </Box>
          </form>
        </Collapse>
      </List>
    </Modal> 
  );
}

