import { FC, MouseEvent } from 'react';
import { IconButton, Button, List, ListItem, ListItemText, Box, Collapse } from '@mui/material';
import { ArrowDropDown, ArrowDropUp, Close } from '@mui/icons-material';
import { useTranslation } from '../translation/translation';
import { Modal } from '../modal/modal';
import { KEYS, useStore } from '../store/store';

interface Props {
  id: string;
}

export const ElementRemove: FC<Props> = (props) => {
  const store = useStore();
  const [t] = useTranslation();
  
  const id = props.id;

  const onClick = (ev: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    ev.stopPropagation();

    store.elementRemove(id);
    store.dialogChange([KEYS.ELEMENT_REMOVE, id]);
  }

  return (
    <Modal
      style={{ pointerEvents: 'auto', minWidth: '320px' }} 
      id={[KEYS.ELEMENT_REMOVE, id].join('-')} 
      open={!!store.dialog([KEYS.ELEMENT_REMOVE, id])}
    >
      <List sx={{ padding: '0px' }}>
        <ListItem sx={{ padding: '8px 8px 8px 12px' }}>
          <ListItemText primary={t('Remove Element')} primaryTypographyProps={{ color: 'primary', variant: 'button' }} />
          <Box>
            <IconButton size="small" onClick={(ev) => {
              ev.stopPropagation();
              store.collapseChange([KEYS.ELEMENT_REMOVE, id]);
            }}>
              {!store.collapse([KEYS.ELEMENT_REMOVE, id])
                ? <ArrowDropDown fontSize="small" />
                : <ArrowDropUp fontSize="small" />}
            </IconButton>
            <IconButton size="small" onClick={(ev) => {
              ev.stopPropagation();
              store.dialogChange([KEYS.ELEMENT_REMOVE, id]);
            }}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
        </ListItem>
        <Collapse className="MuiDialogContent-collapse" in={!store.collapse([KEYS.ELEMENT_REMOVE, id])} timeout="auto">
          <Box sx={{ padding: '0 12px' }}>
            {t('Are you sure you want delete this item?')}
          </Box>
          <Box sx={{
            padding: '8px'
          }}>
            <Button color="inherit" onClick={(ev) => {
              ev.stopPropagation();
              store.dialogChange([KEYS.ELEMENT_REMOVE, id]);
            }}>{t('Cancel')}</Button>
            <Button onClick={onClick}>{t('Confirm')}</Button>
          </Box>
        </Collapse>
      </List>
    </Modal>
  );
}
