import { Chip } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { DATA, useStore } from '../store/store';

interface Props {
  program: string;
  element: string;
  data: DATA;
}

export const Label: FC<Props> = (props) => {
  const store = useStore();
  const [label, setLabel] = useState<string>();

  const d = props.data;
  const p = props.program;
  const e = store.element(props.element);
  const o = store.options(p, e.type, d);  

  const value = e.data && e.data[d] ? e.data[d] : undefined;
  const option = o.find((i) => i.id === value);

  useEffect(() => {
    setLabel(option?.label || option?.id || value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e]);

  return (
    <>
      {(option || value) && <Chip onMouseOver={() => {
        setLabel(option?.id || value);
      }} onMouseOut={() => {
        setLabel(option?.label || value)
      }} label={label} variant="outlined" size="small" sx={{
        height: '20px',
        position: 'relative',
        zIndex: 10000
      }} />}
    </>
  );
}
