import { Box, CircularProgress, Collapse, IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { grey } from '@mui/material/colors';
import { ArrowDropDown, ArrowDropUp, Close, HighlightOffOutlined, PlayCircleOutlineOutlined, PauseCircleOutlineOutlined, TerminalRounded, DownloadOutlined } from '@mui/icons-material';
import { useTranslation } from '../translation/translation';
import { API_URL, KEYS, THEMES, TIMEOUT, useStore } from '../store/store';
import { Modal } from '../modal/modal';

interface Data {
  id: number;
  text: string; 
}

export const Debug: FC = () => {
  const [color, setColor] = useState<'primary' | 'error'>('primary');
  const [data, setData] = useState<Data[]>([]);
  const [play, setPlay] = useState(true);
  const [t] = useTranslation();
  const ref = useRef<HTMLParagraphElement>(null);
  const store = useStore();
  const theme = store.theme();

  const isDialog = store.dialog(KEYS.DEBUG);
  useEffect(() => {
    if (isDialog && play) {
      const onOpen = (ev: EventSourceEventMap['open']) => {
        setData([]);
        setColor('primary');
      }
      const onError = (ev: EventSourceEventMap['error']) => {
        console.error(ev);
        setColor('error');
      }
      const onMessage = (ev: EventSourceEventMap['message']) => {
        const text = ev.data.toString();
        setData((data) => [...data, { id: data.length+1, text }]);
        setTimeout(() => ref.current?.scrollIntoView(), 1);
      }

      const eventSource = new EventSource(`${API_URL}/debug`);
      eventSource.addEventListener('error', onError);
      eventSource.addEventListener('open', onOpen);
      eventSource.addEventListener('message', onMessage);
      return () => {
        eventSource.removeEventListener('open', onOpen);
        eventSource.removeEventListener('error', onError);
        eventSource.removeEventListener('message', onMessage);
        eventSource.close();
      };
    }
  // eslint-disable-next-line
  }, [isDialog, play]);

  return (
    <Fragment>
      <Tooltip title={t('Debug')} arrow>
        <IconButton onClick={(ev) => {
          ev.stopPropagation();
          store.dialogChange(KEYS.DEBUG);

          const token = store.token();
          const method = 'POST';
          const signal = AbortSignal.timeout(TIMEOUT);
          const headers = { 'Authorization': `Basic ${token}` };
          const url = `${API_URL}/debug`;
          const body = JSON.stringify({ enable: true });
          fetch(url, { method, signal, headers, body }).catch(console.error);
        }}>
          <TerminalRounded color={store.dialog(KEYS.DEBUG) ? 'primary' : 'inherit'} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Modal
        style={{ pointerEvents: 'auto', minWidth: '720px' }} 
        id={KEYS.DEBUG}
        open={!!store.dialog(KEYS.DEBUG)}
      >
        <List sx={{ padding: '0px', overflow: 'hidden' }}>
          <ListItem sx={{ padding: '8px 8px 8px 12px' }}>
            <ListItemText primary={t('Debug')} primaryTypographyProps={{ color: 'primary', variant: 'button' }} />
            <Box>
              <IconButton size="small" onClick={(ev) => {
                const blob = new Blob([data.map((i) => i.text).join('/n')],{ type:'text/plain;charset=utf-8;' });
                const href = URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.href = href;
                link.download = `${Date.now()}-debug.txt`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}>
                <DownloadOutlined fontSize="small"/>
              </IconButton>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                setPlay(!play);
              }}>
                {play ? (
                  <PauseCircleOutlineOutlined fontSize="small" />
                ) : (
                  <PlayCircleOutlineOutlined fontSize="small" />
                )}
              </IconButton>
              <IconButton disabled={!data.length} size="small" onClick={(ev) => {
                ev.stopPropagation();
                setData([]);
              }}>
                <HighlightOffOutlined fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.collapseChange(KEYS.DEBUG);
              }}>
                {!store.collapse(KEYS.DEBUG)
                  ? <ArrowDropDown fontSize="small" />
                  : <ArrowDropUp fontSize="small" />}
              </IconButton>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.dialogChange(KEYS.DEBUG);

                const token = store.token();
                const method = 'POST';
                const signal = AbortSignal.timeout(TIMEOUT);
                const headers = { 'Authorization': `Basic ${token}` };
                const url = `${API_URL}/debug`;
                const body = JSON.stringify({ enable: false });
                fetch(url, { method, signal, headers, body }).catch(console.error);
              }}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </ListItem>
          
          <Collapse className="MuiDialogContent-collapse" in={!store.collapse(KEYS.DEBUG)} timeout="auto">
            <Box sx={{
              overflow: 'scroll',
              height: '200px',
              padding: '8px 12px 4px 12px',
              fontSize: '11px',
              fontFamily: '"Lucida Console", "Courier New", monospace',
              color: theme === THEMES.LIGHT ? grey[900] : grey[50],
              '& p': {
                margin: '4px 0px'
              },
              '& p:nth-of-type(1)': {
                marginTop: '0px',
              },
              display: 'flex',
              justifyContent: 'end',
              flexDirection: 'column',
            }}>
              <Box>
                {data.map((i, key) => (
                  <p key={key}><span>{'>'}</span> {i.text.trim()}</p>
                ))}
              </Box>
              <p ref={ref} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
                <span className="blink">{'>'}</span>
                {play ? (
                  <CircularProgress color={color} size={12} sx={{ marginRight: '6px' }}/>
                ) : null}
              </p>
            </Box>
          </Collapse>
        </List>
      </Modal>
    </Fragment>
  );
}
