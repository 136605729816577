import { Add, ArrowDropDown, ArrowDropUp, AssignmentOutlined, ClearOutlined, Close, DeleteOutlineOutlined, DoneOutlined, EditOutlined } from '@mui/icons-material';
import { Box, Collapse, IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import { GridRenderCellParams, DataGrid, GridColDef, renderEditBooleanCell, renderBooleanCell, GridRenderEditCellParams, GridActionsCellItem, GridRowModes, GridRowModesModel, GridRowParams } from '@mui/x-data-grid';
import { FC, Fragment, useEffect, useState } from 'react';
import { renderEdit } from '../edit-cell/edit-cell';
import { useTranslation } from '../translation/translation';
import { Modal } from '../modal/modal';
import { KEYS, useStore, Variable, VARIABLE_TYPES } from '../store/store';

export const Variables: FC = () => {
  const store = useStore();
  const [t] = useTranslation();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState<Array<Variable>>([]);
  const [loading, setLoading] = useState(true);

  const programs = store.programs();
  const program = programs[0];

  useEffect(() => {
    setRows(program.variables);
    setLoading(false);
  }, [program]);

  const columns: Array<GridColDef> = [{
    field: 'id',
  }, {
    field: 'name',
    headerName: t('Name'),
    editable: true,
    sortable: false,
    minWidth: 120,
  }, {
    field: 'description',
    headerName: t('Description'),
    editable: true,
    sortable: false,
    flex: 1,
  }, {
    field: 'type',
    headerName: t('Type'),
    type: 'singleSelect',
    valueOptions: ['DINT', 'BOOL', 'LREAL'],
    editable: true,
    sortable: false,
    minWidth: 100,
  }, {
    field: 'value',
    headerName: t('Value'),
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      if (renderBooleanCell && params.row.type === VARIABLE_TYPES.BOOL) {
        return renderBooleanCell(params);
      }
      if (params.row.type === VARIABLE_TYPES.DINT) {
        return params.value;
      }
      if (params.row.type === VARIABLE_TYPES.LREAL) {
        return params.value;
      }
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      if (renderEditBooleanCell && params.row.type === VARIABLE_TYPES.BOOL) {
        return renderEditBooleanCell(params);
      }
      if (params.row.type === VARIABLE_TYPES.DINT) {
        return renderEdit({ ...params, type: 'number' });
      }
      if (params.row.type === VARIABLE_TYPES.LREAL) {
        return renderEdit({ ...params, type: 'number' });
      }
    },
    editable: true,
    sortable: false,
  }, {
    field: 'retain',
    headerName: t('Retain'),
    type: 'boolean',
    editable: true,
    sortable: false,
    minWidth: 60,
  }, {
    field: 'actions',
    type: 'actions',
    align: 'right',
    headerAlign: 'right',
    width: 88,
    renderHeader: () => {
      return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <IconButton size="small" onClick={(ev) => {
            ev.stopPropagation();

            const id = `${Math.floor(Math.random() * Date.now())}`;
            const name = '';
            const description = '';
            const type = VARIABLE_TYPES.DINT;
            const value = 0;
            const retain = false;
            const isNew = true;
            setRows([{ id, name, description, type, value, retain, isNew } as any, ...rows]);

            const mode = GridRowModes.Edit;
            const fieldToFocus = 'name';
            setRowModesModel({ ...rowModesModel, [id]: { mode, fieldToFocus } });
          }}>
            <Add fontSize="small" />
          </IconButton>
        </div>
      )
    },
    getActions: (params: GridRowParams) => {
      const id = params.id.toString();
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<DoneOutlined />}
            label={t('Save')}
            onClick={() => {
              const mode = GridRowModes.View;
              setRowModesModel({ ...rowModesModel, [id]: { mode } });
            }}
          />,
          <GridActionsCellItem
            icon={<ClearOutlined />}
            label={t('Cancel')}
            onClick={() => {
              setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
              });
              const editedRow: any = rows.find((row) => row.id === id);
              if (editedRow && editedRow.isNew) {
                setRows(rows.filter((row) => row.id !== id));
              }
            }}
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditOutlined />}
          label={t('Edit')}
          onClick={() => {
            const mode = GridRowModes.Edit;
            setRowModesModel({ ...rowModesModel, [id]: { mode } });
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlineOutlined />}
          label={t('Delete')}
          onClick={() => {
            const variables = rows.filter((i) => i.id !== id);
            store.programUpdate(id, { variables });
          }}
        />,
      ];
    }
  }];

  return (
    <Fragment>
      <Tooltip title={t('Variables')} arrow>
        <IconButton onClick={(ev) => {
          ev.stopPropagation();
          store.dialogChange(KEYS.VARIABLES);
        }}>
          <AssignmentOutlined color={store.dialog(KEYS.VARIABLES) ? 'primary' : 'inherit'} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Modal
        open={!!store.dialog(KEYS.VARIABLES)}
        style={{ pointerEvents: 'auto', minWidth: '720px' }}
        id={KEYS.VARIABLES}
      >
        <List sx={{ padding: '0px' }}>
          <ListItem sx={{ padding: '8px 8px 8px 12px' }}>
            <ListItemText primary={t('Variables')} primaryTypographyProps={{ color: 'primary', variant: 'button' }} />
            <Box>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.collapseChange(KEYS.VARIABLES);
              }}>
                {!store.collapse(KEYS.VARIABLES)
                  ? <ArrowDropDown fontSize="small" />
                  : <ArrowDropUp fontSize="small" />}
              </IconButton>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.dialogChange(KEYS.VARIABLES);
              }}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </ListItem>
          <Collapse className="MuiDialogContent-collapse" in={!store.collapse(KEYS.VARIABLES)} timeout="auto">
            <Box sx={{
              height: '318px',
            }}>
              <DataGrid
                disableColumnMenu
                loading={loading}
                editMode="row"
                pagination
                localeText={{
                  noRowsLabel: t('No rows'),
                  noResultsOverlayLabel: t('No results found.'),
                  MuiTablePagination: {
                    labelRowsPerPage: t('Rows per page:'),
                    labelDisplayedRows: ({ from, to, count }) => 
                      `${from}–${to} ${t('of')} ${count !== -1 ? count : `${t('more than')} ${to}`}`,
                  }
                }}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[10, 25, 50]}
                columnVisibilityModel={{
                  id: false,
                }}
                rowModesModel={rowModesModel}
                processRowUpdate={(newRow: any) => {
                  const updatedRow = { ...newRow, isNew: false };
                  const n = rows.filter((i: any) => i.isNew).map((i) => i.id === newRow.id ? updatedRow : i);
                  const o = rows.filter((i: any) => !i.isNew).map((i) => i.id === newRow.id ? updatedRow : i);
                  const variables = [...o, ...n];
                  store.programUpdate(program.id, { variables });
                  return updatedRow;
                }}
                onRowModesModelChange={setRowModesModel}
                onRowEditStart={(params, ev) => {
                  ev.stopPropagation();
                  ev.defaultMuiPrevented = false;
                }}
                onRowEditStop={(params, ev) => {
                  ev.defaultMuiPrevented = true;
                }}
                rows={rows}
                columns={columns}
                // slots={{ footer }}
              />
            </Box> 
          </Collapse>
        </List>
      </Modal>
    </Fragment>
  )
}
