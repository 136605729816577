import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useEffect, useState} from 'react';
import { LANGUAGES, useStore } from '../store/store';

const translations: { [k in LANGUAGES]: Record<string, string> } = {
  en: require('../translations/en.json'),
  ua: require('../translations/ua.json'),
};

export const useTranslation = (): [(key: string, language?:LANGUAGES) => string, LANGUAGES] => {
  const store = useStore();
  const l = store.language();
  return [(key, language?) => {
    return translations[language || l][key] || key;
  }, l];
}

interface Props {
  code: string;
}

const Flag: FC<Props> = (props) => {
  return (
    <div style={{ display: 'flex', height: '16px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img
          loading="lazy"
          width="16"
          src={`https://flagcdn.com/w20/${props.code}.png`}
          srcSet={`https://flagcdn.com/w40/${props.code}.png 2x`}
          alt=""
        />
      </div>
    </div>
  )
}

enum COUNTRIES {
  US = 'us',
  GB = 'gb',
  UA = 'ua',
}

const EN_COUNTRIES: any = {
  [COUNTRIES.US]: 'us',
  [COUNTRIES.GB]: 'gb',
}

export const Translation: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [country, setCountry] = useState('');
  const [t, language] = useTranslation();
  const store = useStore();

  const languages = [LANGUAGES.EN, LANGUAGES.UA];

  useEffect(() => {
    const url = 'https://ipapi.co/json';
    const method = 'GET';
    fetch(url, { method })
      .then((data) => data.json())
      .then((data) => {
        setCountry(data.country_code.toLowerCase());
      })
      .catch((err) => console.log(err))
  }, []);

  const code = (l: LANGUAGES) => {
    if (l === LANGUAGES.EN) {
      return EN_COUNTRIES[country] || COUNTRIES.US;
    }
    return l;
  }

  return (
    <>
      <IconButton sx={{ marginLeft: '8px' }} color="inherit" onClick={(ev) => {
        ev.stopPropagation();
        setAnchorEl(ev.currentTarget);
      }}>
        <Flag code={code(language)}/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(undefined);
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {languages.map((i, key) => (
          <MenuItem key={key} data-id={i} onClick={(ev) => {
            const id = ev.currentTarget.getAttribute('data-id');
            store.changeLanguage(id as LANGUAGES);
            setAnchorEl(undefined);
          }}>
            <Flag code={code(i)}/><span style={{ marginLeft: '8px' }}>{t('Language', i)}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
