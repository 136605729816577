import { FC, createElement } from 'react';
import { useTheme } from '@mui/material';
import { PROGRAM_ELEMENTS, ELEMENT_TYPES, ELEMENT_HEIGHT, ELEMENT_WIDTH } from '../store/store';

interface Props {
  type: ELEMENT_TYPES;
  id?: string;
}

export const ElementPreview: FC<Props> = (props) => {
  const theme = useTheme();

  const id = props.id;
  const type = props.type;
  const color = theme.palette.success.main;

  const width = ELEMENT_WIDTH;
  const height = ELEMENT_HEIGHT;

  return (
    <div style={{
      cursor: 'grabbing',
      width: `${width}px`,
      height: `${height}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      {createElement(PROGRAM_ELEMENTS[type], { color, id })}
    </div>
  );
}