import { Box, Switch, Tooltip, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from '../translation/translation';
import { useStore, THEMES } from '../store/store';

export const Theme: FC = () => {
  const [t] = useTranslation();
  const store = useStore();
  const theme = useTheme();
  const checked = theme.palette.mode === THEMES.LIGHT;

  return (
    <Tooltip title={t(`Change to ${checked ? THEMES.DARK : THEMES.LIGHT} theme`)} arrow placement="top">
      <Box sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '8px'
      }}>
        <Switch disableRipple size="small" focusVisibleClassName=".Mui-focusVisible" checked={checked} onChange={(ev) => {
          ev.stopPropagation();
          store.themeChange();
        }} />
      </Box>
    </Tooltip>
  )
} 
