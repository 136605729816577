import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from '../translation/translation';
import { Theme } from '../theme/theme';

const { version } = require('../../package.json');

export const Copyright: FC = () => {
  const [t] = useTranslation();
  return (
    <Box sx={{
      padding: '8px 12px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      clear: 'both',
    }}>
      <Typography variant="caption" display="block" color="textSecondary">
        {t('Copyright')} © {new Date().getFullYear()} FET<span style={{ fontSize: '8px'}}>DEVICES</span> <span style={{ fontSize: '8px' }}>{version}</span>
      </Typography>
      <Theme />
    </Box>
  )
}