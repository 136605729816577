import { Box, CircularProgress, Switch, Tooltip } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from '../translation/translation';
import { API_URL, TIMEOUT, useStore, STATUS_CODES } from '../store/store';

export const Status: FC = () => {
  const [color, setColor] = useState<'success' | 'warning' | 'error'>('success');
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const store = useStore();

  const status = store.status();
  const token = store.token();

  useEffect(() => {
    setInterval(() => {
      const method = 'GET';
      const signal = AbortSignal.timeout(TIMEOUT);
      const headers = { 'Authorization': `Basic ${token}` };
      const url = `${API_URL}/state`;
      fetch(url, { method, signal, headers })
        .then((i) => {
          if (!i.ok) {
            setColor('error');
            throw new Error('Houston, we have a problem...');
          }
          return i.json();
        })
        .then((i) => {
          if (i.state === STATUS_CODES.NOT_INITED) {
            setColor('warning');
            return;
          }
          if (i.state === STATUS_CODES.RUN) {
            setColor('success');
            return;
          }
          setColor('error');
        })
        .catch((err) => {
          console.error(err);
          setColor('error');
        });
    }, 5000);
  // eslint-disable-next-line
  }, []);

  const checked = STATUS_CODES.NOT_INITED === status || STATUS_CODES.RUN === status;

  return (
    <Tooltip title={t('Change device state')} arrow placement="top">
      <Box sx={{
        position: 'relative',
      }}>
        {loading ? <CircularProgress size={12} sx={{
          position: 'absolute',
          zIndex: 1,
          top: '13px',
          left: checked ? '33px' : '13px'
        }}/> : null}
        <Switch disableRipple checked={checked} disabled={loading} color={color} onChange={(ev, value) => {
            ev.stopPropagation();
            setLoading(true);
            const method = 'POST';
            const signal = AbortSignal.timeout(TIMEOUT);
            const state = value ? STATUS_CODES.RUN : STATUS_CODES.STOP;
            const body = JSON.stringify({ state });
            const headers = { 'Authorization': `Basic ${token}` };
            const url = `${API_URL}/state`;
            fetch(url, { method, signal, body, headers })
              .then((i) => {
                if (!i.ok) {
                  throw new Error('Houston, we have a problem...');
                }
                return i.json();
              })
              .then(() => {
                setLoading(false);
                setColor('success');
                store.statusChange(state);
              })
              .catch(() => {
                setLoading(false);
                setColor('error');
                store.statusChange(state === STATUS_CODES.RUN ? STATUS_CODES.NOT_INITED : STATUS_CODES.STOP);
              });
          }}/>
      </Box>
    </Tooltip>
  )
} 
