import { FC } from 'react';
import { Box } from '@mui/material';
import { useDragLayer } from 'react-dnd';
import { ElementPreview } from '../element-preview/element-preview';
import { ELEMENT_HEIGHT, ELEMENT_WIDTH } from '../store/store';

export const ProgramPreview: FC = () => {
  const params = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const isDragging = params.isDragging;
  const clientOffset= params.clientOffset;
  const currentOffset = params.currentOffset;
  const item = params.item;

  if (!isDragging || !currentOffset) {
    return null
  }

  const x = clientOffset?.x || 0;
  const y = clientOffset?.y || 0;

  const id = item.id;
  const type = item.type;

  const transform = `translate(${x-ELEMENT_WIDTH/2}px, ${y-ELEMENT_HEIGHT/2}px)`

  return (
    <Box sx={{
      position: 'fixed',
      pointerEvents: 'none',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    }}>
      <Box sx={{ transform }}>
        <ElementPreview type={type} id={id} />
      </Box>
    </Box>
  )
}