import { FC } from 'react';
import { ELEMENT_HEIGHT, ELEMENT_WIDTH, DATA } from '../store/store';
import { Label } from '../label/label';

interface Props {
  color: string;
  program?: string;
  id?: string;
}

export const ElementNo: FC<Props> = (props) => {
  const width = ELEMENT_WIDTH;
  const height = ELEMENT_HEIGHT;

  const id = props.id;
  const color = props.color;
  const program = props.program;

  if (id && program) {
    return (
      <div style={{
        color,
        position: 'relative',
        fontSize: '12px',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: `${height}px`,
          width: `${width}px`,
        }}>
          <div style={{
            position: 'absolute',
            top: '-5px',
          }}>NO</div>
          <div style={{
            transform: 'scale(0.6)',
            transformOrigin: 'left',
            position: 'absolute',
            top: '-6px',
            left: '34px',
          }}>
            <Label program={program} element={id} data={DATA.IN} />
          </div>
          <div style={{
            borderBottom: `2px solid ${color}`,
            width: '100%',
            marginBottom: '1px',
          }}></div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <div style={{
              height: '20px',
              borderLeft: `2px solid ${color}`,
              marginRight: '7px',
            }}></div>
            <div style={{
              height: '20px',
              borderRight: `2px solid ${color}`,
              marginLeft: '7px',
            }}></div>
          </div>
          <div style={{
            borderBottom: `2px solid ${color}`,
            width: '100%',
            marginBottom: '1px',
          }}></div>
        </div>
      </div>
    );
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: `${height}px`,
      width: `${width}px`,
    }}>
      <div style={{
        borderBottom: `2px solid ${color}`,
        width: '100%',
        marginBottom: '1px',
      }}></div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div style={{
          height: '20px',
          borderLeft: `2px solid ${color}`,
          marginRight: '7px',
        }}></div>
        <div style={{
          height: '20px',
          borderRight: `2px solid ${color}`,
          marginLeft: '7px',
        }}></div>
      </div>
      <div style={{
        borderBottom: `2px solid ${color}`,
        width: '100%',
        marginBottom: '1px',
      }}></div>
    </div>
  );
}