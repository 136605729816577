import { AppBar, Box, Button, Card, CardActions, CardContent, CssBaseline, Grid, List, ListItem, ListItemText, Tab, Tabs, TextField, Toolbar, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Title } from '../title/title';
import { Translation, useTranslation } from '../translation/translation';
import { Copyright } from '../copyright/copyright';
import { API_URL, TIMEOUT, useStore } from '../store/store';
import { Logout } from '../logout/logout';

export const Root: FC = () => {
  const theme = useTheme();
  const store = useStore();
  const [t] = useTranslation();
  const [value, setValue] = useState('system');
  const [data, setData] = useState<any>();

  const token = store.token();

  useEffect(() => {
    const method = 'GET';
    const signal = AbortSignal.timeout(TIMEOUT);
    const url = `${API_URL}/info`;

    fetch(url, { method, signal })
      .then((i) => {
        if (!i.ok) {
          throw new Error('Houston, we have a problem...');
        }
        return i.json();
      })
      .then((i) => {
        setData(i);
      })
      .catch((err) => {
        store.notification({
          type: 'error',
          text: err.message,
        });
      });
  // eslint-disable-next-line
  }, []);

  return (
    <Box className="plc" sx={{
      display: 'flex'
    }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{
        zIndex: theme.zIndex.drawer + 1,
      }}>
        <Toolbar>
          <Title />
          <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', flexGrow: 1, mr: 1 }}>
            {token && (
              <Button sx={{ marginLeft: '8px' }} color="inherit" component={Link} to="/password">{t('Change Access')}</Button>
            )}
            {token && (
              <Button sx={{ marginLeft: '8px' }} color="inherit" component={Link} to="/wifi">{t('Change WiFi')}</Button>
            )}
            {token && (
              <Button sx={{ marginLeft: '8px' }} color="inherit" variant="outlined" component={Link} to="/plc">{t('PLC Playground')}</Button>
            )}
            {token ? (
              <Logout />
            ) : (
              <Button sx={{ marginLeft: '8px' }} color="inherit" variant="outlined" component={Link} to="/auth">{t('Login')}</Button>
            )}
            <Translation />
          </Box>
        </Toolbar>
      </AppBar>
      <Box>
        <Toolbar />
        <Box sx={{
          minHeight: '100vh',
          minWidth: '100vw',
        }}>
          <Box sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
            <Tabs
              sx={{
                maxWidth: '100%',
              }}
              variant="scrollable"
              scrollButtons={false}
              value={value}
              textColor="inherit"
              onChange={(ev, index) => {
                setValue(index)
              }}
            >
              <Tab value='system' label={t('System')} sx={{
                padding: '12px',
                '&.MuiTab-root': {
                  minWidth: '10px',
                },
              }} />
              <Tab value='wifi' label={t('WI-FI')} sx={{
                padding: '12px',
                '&.MuiTab-root': {
                  minWidth: '10px',
                },
              }} />
              <Tab value='ethernet' label={t('Ethernet')} sx={{
                padding: '12px',
                '&.MuiTab-root': {
                  minWidth: '10px',
                },
              }}/>
            </Tabs>
          </Box>
          {value === 'system' && (
            <Box role="tabpanel" sx={{
              padding: '16px',
            }}>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent sx={{
                      padding: '6px 12px 8px 12px',
                      paddingBottom: '8px !important'
                    }}>
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="chip"
                        label={t('Chip')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.system?.chip}
                      />
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="cores"
                        label={t('Cores')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.system?.cores}
                      />
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="modification"
                        label={t('Modification')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.system?.modification}
                      />
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="revision"
                        label={t('Hardware Revision')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.system?.revision}
                      />
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="free_heap"
                        label={t('Free Heap')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.system?.free_heap}
                      />
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="fw_version"
                        label={t('Firmware Version')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.system?.fw_version}
                      />
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="hw_version"
                        label={t('Hardware Version')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.system?.hw_version}
                      />
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="datetime"
                        label={t('Date and Time')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.system?.datetime}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={8} xs={12}>
                  <Card>
                    <CardContent sx={{
                      padding: '6px 12px 8px 12px',
                      paddingBottom: '8px !important'
                    }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {t('System')}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {t('The System information of your microcontroller provides a comprehensive overview of your device\'s core information and status. It serves as a hub for essential details, allowing you to monitor and manage key aspects of your microcontroller\'s operation.')}
                      </Typography>
                      <List>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('Chip')}
                            secondary={t('Identify the core processing unit of your microcontroller.')}
                          />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('Cores')}
                            secondary={t('Discover the number of processor cores your microcontroller utilizes, providing insights into its processing capabilities.')}
                          />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('Modification')}
                            secondary={t('Learn about the specific modification applied to your microcontroller, which includes WiFi, Bluetooth, and BLE (Bluetooth Low Energy) support, expanding its connectivity options.')}
                          />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('Hardware Revision')}
                            secondary={t('Get a quick reference to the hardware revision, aiding in tracking and identifying specific versions of your device.')}
                          />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('Free Heap')}
                            secondary={t('Monitor the available free memory heap, ensuring efficient resource management for your applications.')}
                          />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('Firmware Version')}
                            secondary={t('Keep tabs on the installed firmware version, which is crucial for compatibility and feature updates.')}
                          />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('Hardware Version')}
                            secondary={t('Identify the hardware version, assisting in maintenance and troubleshooting tasks.')}
                          />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('Date and Time')}
                            secondary={t('Stay informed about the current date and time, essential for synchronization and scheduling tasks.')}
                          />
                        </ListItem>
                      </List>
                      <Typography variant="body2" color="text.secondary">
                        {t('The System information offers a snapshot of your microcontroller\'s core attributes, enabling you to make informed decisions, perform diagnostics, and ensure optimal performance for your connected applications and projects.')}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button color="primary">{t('Learn more')}</Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          )}
          {value === 'wifi' && (
            <Box role="tabpanel" sx={{
              padding: '16px',
            }}>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent sx={{
                      padding: '6px 12px 8px 12px',
                      paddingBottom: '8px !important'
                    }}>
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="state"
                        label={t('State')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.wifi?.state}
                      />
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="ssid"
                        label={t('SSID')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.wifi?.ssid}
                      />
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="ip"
                        label={t('IP Address')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.wifi?.ip}
                      />
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="max"
                        label={t('MAC Address')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.wifi?.mac}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={8} xs={12}>
                  <Card>
                    <CardContent sx={{
                      padding: '6px 12px 8px 12px',
                      paddingBottom: '8px !important'
                    }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {t('WI-FI')}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {t('The WiFi information on your microcontroller provides a central hub for managing and monitoring your device\'s wireless network connectivity. It offers valuable insights and control options for your WiFi connection.')}
                      </Typography>
                      <List>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('Connection Status')}
                            secondary={t('Instantly check the current status of your WiFi connection. Whether it\'s Connected/Disconnected this status provides real-time feedback on your device connectivity. Take control of your WiFi connection with the ability to connect or disconnect as needed, giving you flexibility and control over your network connectivity.')}
                          />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('SSID (Network Name)')}
                            secondary={t('Identify the WiFi network (SSID) your microcontroller is currently connected to. Easily switch between networks or ensure that you\'re connected to the intended one.')}
                          />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('IP Address')}
                            secondary={t('Know your microcontroller IP address on the WiFi network. This is essential for accessing and controlling your device remotely.')}
                          />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('MAC Address')}
                            secondary={t('Find your microcontroller unique MAC address for WiFi communications. This address is crucial for network administration and device identification.')}
                          />
                        </ListItem>
                      </List>
                      <Typography variant="body2" color="text.secondary">
                        {t('The WiFi information empowers you to manage your microcontroller\'s wireless network settings efficiently. Whether you need to check the connection status, switch networks, or access network-specific details, this page offers the tools you need to stay connected and in control.')}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button color="primary">{t('Learn more')}</Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          )}
          {value === 'ethernet' && (
            <Box role="tabpanel" sx={{
              padding: '18px',
            }}>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent sx={{
                      padding: '6px 12px 8px 12px',
                      paddingBottom: '8px !important'
                    }}>
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="state"
                        label={t('State')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.ethernet?.state}
                      />
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="ip"
                        label={t('IP Address')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.ethernet?.ip}
                      />
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="max"
                        label={t('MAC Address')}
                        InputLabelProps={{ shrink: true }}
                        value={data?.ethernet?.mac}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={8} xs={12}>
                  <Card>
                    <CardContent sx={{
                      padding: '6px 12px 8px 12px',
                      paddingBottom: '8px !important'
                    }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {t('Ethernet')}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {t('The Ethernet information on your microcontroller is your gateway to managing the wired network connection of your device. It provides essential information and controls to ensure seamless Ethernet connectivity.')}
                      </Typography>
                      <List>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('Connection Status')}
                            secondary={t('Stay informed about the current status of your Ethernet connection. Whether it\'s Connected/Disconnected this status indicator allows you to monitor the stability of your wired network connection. Take control of your Ethernet connection with the ability to connect or disconnect when needed. This feature gives you flexibility and control over your wired network connectivity.')}
                          />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('IP Address')}
                            secondary={t('Access your microcontroller assigned IP address on the Ethernet network. This is vital for communication and remote access to your device over a wired connection.')}
                          />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText
                            primary={t('MAC Address')}
                            secondary={t('Identify your microcontroller unique MAC address for Ethernet communication. This address plays a pivotal role in network configuration and device identification.')}
                          />
                        </ListItem>
                      </List>
                      <Typography variant="body2" color="text.secondary">
                        {t('The Ethernet information offers a user-friendly interface to manage and monitor your microcontroller\'s Ethernet connection. Whether you need to check the connection status, access network-specific details, or control your wired network connection, this page provides the tools you need to maintain a stable and reliable network connection.')}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button color="primary">{t('Learn more')}</Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        <Copyright />
      </Box>
    </Box>
  );
}