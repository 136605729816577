import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from '../translation/translation';
import { Copyright } from '../copyright/copyright';
import { Link } from 'react-router-dom';

export const Forbidden: FC = () => {
  const [t] = useTranslation();
  return (
    <Paper className="forbidden" square elevation={0}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Typography variant="h3" color="textSecondary">{t('403')}</Typography>
            <Divider orientation="vertical" variant="middle" flexItem sx={{
              m: 2
            }}/>
            <Typography variant="subtitle1" color="textSecondary">{t('No this time, access forbidden.')}</Typography>
          </Box>
          <Button sx={{ marginLeft: '8px' }} color="inherit" variant="outlined" component={Link} to="/">{t('Back Home')}</Button>
        </Box>
      </Box>
      <Copyright />
    </Paper>
  )
}