import { Box, Button, Collapse, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { FC, Fragment } from 'react';
import { ArrowDropDown, ArrowDropUp, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { KEYS, useStore } from '../store/store';
import { useTranslation } from '../translation/translation';
import { Modal } from '../modal/modal';

export const Logout: FC = () => {
  const navigate = useNavigate();
  const store = useStore();
  const [t] = useTranslation();
  return (
    <Fragment>
      <Button sx={{ marginLeft: '8px' }} color="inherit" variant="outlined" onClick={()=> {
        store.dialogChange(KEYS.LOGOUT_CONFIRM);
      }}>{t('Logout')}</Button>
      <Modal
        style={{ pointerEvents: 'auto', minWidth: '320px' }}
        id={KEYS.LOGOUT_CONFIRM}
        open={!!store.dialog(KEYS.LOGOUT_CONFIRM)}
      >
        <List sx={{ padding: '0px' }}>
          <ListItem sx={{ padding: '8px 8px 8px 12px' }}>
            <ListItemText primary={t('Logout')} primaryTypographyProps={{ color: 'primary', variant: 'button' }} />
            <Box>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.collapseChange(KEYS.LOGOUT_CONFIRM);
              }}>
                {!store.collapse(KEYS.LOGOUT_CONFIRM)
                  ? <ArrowDropDown fontSize="small" />
                  : <ArrowDropUp fontSize="small" />}
              </IconButton>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.dialogChange(KEYS.LOGOUT_CONFIRM);
              }}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </ListItem>
          <Collapse className="MuiDialogContent-collapse" in={!store.collapse(KEYS.LOGOUT_CONFIRM)} timeout="auto">
            <Box sx={{ padding: '0 12px' }}>
              {t('Are you sure you want to logout?')}
            </Box>
            <Box sx={{
              padding: '8px'
            }}>
              <Button color="inherit" onClick={(ev) => {
                ev.stopPropagation();
                store.dialogChange(KEYS.LOGOUT_CONFIRM);
              }}>{t('Cancel')}</Button>
              <Button onClick={(ev) => {
                ev.stopPropagation();
                store.dialogChange(KEYS.LOGOUT_CONFIRM);
                store.tokenChange();
                navigate('/');
              }}>{t('Confirm')}</Button>
            </Box>
          </Collapse>
        </List>
      </Modal>
    </Fragment>
  )
}