import { FC, Fragment, useState } from 'react';
import { Box, Button, IconButton, Tab, Tabs } from '@mui/material';
import { BuildCircleOutlined, Close } from '@mui/icons-material';
import { Rungs } from '../rungs/rungs';
import { useTranslation } from '../translation/translation';
import { Json } from '../json/json';
import { Settings } from '../settings/settings';
import { Tags } from '../tags/tags';
import { KEYS, useStore } from '../store/store';
import { Status } from '../status/status';
import { Variables } from '../variables/variables';
import { Logs } from '../logs/logs';
import { Debug } from '../debug/debug';

export const Program: FC = () => {
  const store = useStore();
  const [value, setValue] = useState(0);
  const [t] = useTranslation();

  const programs = store.programs();
  const tabs = store.tabs();

  const data = programs.filter((i, key) => !key || tabs.includes(i.id));
  const program = data[value];

  return (
    <Fragment>
      <Box sx={{
        borderBottom: 1,
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <Tabs
          sx={{
            maxWidth: '100%',
          }}
          variant="scrollable"
          scrollButtons={false}
          value={value}
          onChange={(ev, index) => {
            setValue(index);
          }}
        >
          {data.map((i, key) => {
            if (!key) {
              return (
                <Tab key={key} label={<BuildCircleOutlined fontSize="small" />} sx={{ 
                  padding: '12px',
                  '&.MuiTab-root': {
                    minWidth: '10px',
                  },
                }} onContextMenu={(ev) => {
                  ev.preventDefault();
                  // to do...
                }} />
              );
            }
            return (
              <Tab key={key} label={
                <Box sx={{ display: 'flex', alignItems: 'center' }} contextMenu="none" onContextMenu={(ev) => {
                  ev.preventDefault();
                  // to do...
                }}>
                  {i.name}
                  <Box sx={{
                    position: 'absolute',
                    transform: 'scale(0.7)',
                    right: 0,
                    top: 0,
                  }}>
                    <IconButton size="small" onClick={(ev) => {
                      ev.stopPropagation();
                      setValue(0);
                      store.tabRemove(i.id);
                    }}>
                      <Close fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              } sx={{
                padding: '12px',
                paddingRight: '21px',
                '& .MuiTab-root': {
                  minWidth: '10px',
                }
              }} />
            );
          })}
        </Tabs>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 8px',
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <Debug />
            <Logs />
            <Variables />
            <Tags />
            <Json />
            <Settings />
            <Status />
          </Box>
        </Box>
      </Box>
      {data.map((i, key) => (
        <Box key={key} role="tabpanel" sx={{ marginTop: '8px' }} hidden={value !== key}>
          <Rungs program={program.id} />
        </Box>
      ))}
      <Box sx={{
        padding: '0px 8px',
        display: 'flex'
      }}>
        <Button color="inherit" onClick={(ev) => {
          ev.stopPropagation();
          store.dialogChange(KEYS.RUNG_CREATE);
        }}>{t('Create Rung')}</Button>
      </Box>
      {/* <ProgramScenarios id={i.id} /> */}
      {/* <ProgramVariables id={program.id} /> */}
      {/* <ProgramLogs id={program.id} /> */}
      {/* <ProgramUpdate id={i.id} /> */}
      {/* <IconButton color="inherit" onClick={(ev) => {
        ev.stopPropagation();
        store.dialogChange([KEYS.PROGRAM_SCENARIOS, program.id]);
      }}>
        <PlaylistPlay color={store.dialog([KEYS.PROGRAM_SCENARIOS, program.id]) ? 'primary' : 'inherit'} fontSize="small" />
      </IconButton> */}
      {/* <IconButton color="inherit" onClick={(ev) => {
        ev.stopPropagation();
        store.dialogChange([KEYS.PROGRAM_VARIABLES, program.id]);
      }}>
        <AssignmentOutlined color={store.dialog([KEYS.PROGRAM_VARIABLES, program.id]) ? 'primary' : 'inherit'} fontSize="small" />
      </IconButton>
      <IconButton size="small" onClick={(ev) => {
        ev.stopPropagation();
        store.dialogChange([KEYS.PROGRAM_UPDATE, program.id]);
      }}>
        <EditOutlined color={store.dialog([KEYS.PROGRAM_VARIABLES, program.id]) ? 'primary' : 'inherit'} fontSize="small" />
      </IconButton>
      <IconButton size="small" disabled={!value} onClick={(ev) => {
        ev.stopPropagation();
        store.dialogChange([KEYS.PROGRAM_REMOVE, program.id]);
      }}>
        <DeleteOutlineOutlined fontSize="small" />
      </IconButton> */}
      {/* <IconButton size="small" onClick={(ev) => {
        ev.stopPropagation();
        store.dialogChange([KEYS.PROGRAM_UPDATE, program.id]);
      }}>
        <EditOutlined color={store.dialog([KEYS.PROGRAM_VARIABLES, program.id]) ? 'primary' : 'inherit'} fontSize="small" />
      </IconButton> */}
    </Fragment>
  );
}
