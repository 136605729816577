import { FC } from 'react';
import { PaperProps, Paper } from '@mui/material';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { useStore } from '../store/store';

export const PaperComponent: FC<PaperProps<'div', {}>> = (props) => {
  const store = useStore();
  const id = props.id || '';

  const onStop = (ev: DraggableEvent, data: DraggableData) => {
    const x = data.x;
    const y = data.y;
    store.positionChange(id, { x, y });
  }

  const position = store.position(id);
  const x = position?.x || 0;
  const y = position?.y || 0;

  return (
    <Draggable
      cancel={'[class*="MuiDialogContent-collapse"]'}
      onStop={onStop} 
      position={{ x, y }}
    >
      <Paper {...props} />
    </Draggable>
  )
}
