import { Close } from '@mui/icons-material';
import { Card, CardActionArea, CardContent, IconButton, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from '../translation/translation';

export const Ukraine: FC = () => {
  const [t] = useTranslation();
  const [hidden, setHidden] = useState(false);

  return (
    <Stack hidden={hidden} sx={{
      display: hidden ? 'none' : 'block',
      position: 'fixed',
      zIndex: '2000',
      right: '8px',
      bottom: '8px',
    }} spacing={1}>
      <Card sx={{ width: 350, whiteSpace: 'normal', }}>
        <CardActionArea href="https://war.ukraine.ua/support-ukraine/" target="_blank">
          <div style={{
            height: '12px',
            backgroundColor: 'rgb(0, 87, 183)',
          }}></div>
          <div style={{
            height: '12px',
            backgroundColor: 'rgb(255, 215, 0)',
          }}></div>
          <CardContent>
            <Typography noWrap={false} variant="body2" sx={{
              marginBottom: '8px',
            }}>
              {t('FET Devices stands in solidarity with the Ukrainian people against the Russian invasion.')}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {t('Find out how you can help.')}
            </Typography>
          </CardContent>
        </CardActionArea>
        <IconButton size="small" sx={{
          position: 'absolute',
          bottom: '8px',
          right: '8px',
        }} onClick={(ev) => {
            ev.stopPropagation();
            setHidden(true);
          }}>
            <Close fontSize="small" />
          </IconButton>
      </Card>
    </Stack>
  );
}