import { Add, ArrowDropDown, ArrowDropUp, FolderOpenOutlined, FolderOutlined } from '@mui/icons-material';
import { Box, Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { FC, Fragment } from 'react';
import { KEYS, useStore } from '../store/store';

export const Programs: FC = () => {
  const store = useStore();

  return (
    <Fragment>
      <List sx={{ padding: '0px' }}>
        <ListItem disablePadding>
          <ListItemButton disabled onClick={(ev) => {
            ev.stopPropagation();
            store.collapseChange(KEYS.PROGRAMS);
          }} sx={{ px: 1.5, pr: 1 }}>
            <Tooltip title="Programs" arrow>
              <ListItemIcon>
                {!store.collapse(KEYS.PROGRAMS)
                  ? <FolderOpenOutlined color="primary" fontSize="small" />
                  : <FolderOutlined color="primary" fontSize="small" />}
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Programs" primaryTypographyProps={{ color: 'primary', variant: 'button' }} />
            <Box>
              <Tooltip title="Create program" arrow>
                <IconButton size="small" onClick={(ev) => {
                  ev.stopPropagation();
                  // store.dialogChange(KEYS.PROGRAM_CREATE);
                }}>
                  <Add fontSize="small" />
                </IconButton>
              </Tooltip>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.collapseChange(KEYS.PROGRAMS);
              }}>
                {!store.collapse(KEYS.PROGRAMS)
                  ? <ArrowDropDown fontSize="small" />
                  : <ArrowDropUp fontSize="small" />}
              </IconButton>
            </Box>
          </ListItemButton>
        </ListItem>
        <Collapse in={!store.collapse(KEYS.PROGRAMS)} timeout="auto">
          <List component="div" disablePadding>
      
          </List>
        </Collapse>
      </List>
    </Fragment>
  );
}