import { Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useStore, jsonTransform, KEYS } from '../store/store';
import { ArrowDropDown, ArrowDropUp, FolderOpenOutlined, FolderOutlined, PanoramaFishEye } from '@mui/icons-material';
import { useTranslation } from '../translation/translation';

const examples = [
  require('./example-1.json'),
  require('./example-2.json'),
  require('./example-3.json'),
];

export const Examples: FC = () => {
  const store = useStore();
  const [t] = useTranslation();
  const drawer = store.drawer();
  return (
    <List sx={{ padding: '0px' }}>
      <ListItem disablePadding secondaryAction={
        <IconButton sx={{
          display: drawer ? 'initial' : 'none',
        }} size="small" onClick={(ev) => {
          ev.stopPropagation();
          store.collapseChange(KEYS.EXAMPLES);
        }}>
          {!store.collapse(KEYS.EXAMPLES)
            ? <ArrowDropDown fontSize="small" />
            : <ArrowDropUp fontSize="small" />}
        </IconButton>
      }>
        <ListItemButton onClick={(ev) => {
          ev.stopPropagation();
          store.collapseChange(KEYS.EXAMPLES);
        }} sx={{ padding: '8px 15px' }}>
          <Tooltip title={t('Examples')} arrow>
            <ListItemIcon>
              {!store.collapse(KEYS.EXAMPLES)
                ? <FolderOpenOutlined color="primary" fontSize="small" />
                : <FolderOutlined color="primary" fontSize="small" />}
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t('Examples')} primaryTypographyProps={{ color: 'primary', variant: 'button' }} />
        </ListItemButton>
      </ListItem>
      <Collapse in={!store.collapse(KEYS.EXAMPLES)} timeout="auto">
        <List component="div" disablePadding>
          {examples.map((i, key) => {
            const data = jsonTransform(i);
            return (
              <ListItemButton key={key} sx={{ padding: '8px 13px' }} onClick={(ev) => {
                ev.stopPropagation();
                const programs = [data.programs[0]];
                store.setData({ programs });
              }}>
                <Tooltip title={i.description || i.name} arrow>
                  <ListItemIcon>
                    <div style={{
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      <PanoramaFishEye />
                      <div style={{
                        position: 'absolute',
                        top: '5px',
                        left: '9.5px',
                        fontSize: '9px',
                        fontWeight: 900,
                      }}>{key+1}</div>
                    </div>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  sx={{ margin: '0px' }}
                  primaryTypographyProps={{ variant: 'button', style: { fontSize: '12px', lineHeight: 1.4 } }}
                  secondaryTypographyProps={{ variant: 'caption', style: { lineHeight: 1.3 } }}
                  primary={i.name}
                  secondary={i.description}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </List>
  )
}