import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const Title: FC = () => {
  return (
    <Button sx={{
      padding: '12px 16px',
    }} variant="text" color="inherit" component={Link} to="/">
      <Typography sx={{
        fontSize: '16px',
      }}>
        FET<span style={{ fontSize: '8px' }}>DEVICES</span>
      </Typography>
    </Button>
  );
}