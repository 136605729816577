import { FC, useState, useEffect, Fragment } from 'react';
import { CircularProgress, IconButton, List, ListItem, ListItemText, Box, Collapse, Tooltip } from '@mui/material';
import { JsonViewer } from '@textea/json-viewer'
import { ArrowDropDown, ArrowDropUp, Close, IntegrationInstructionsOutlined } from '@mui/icons-material';
import { JsonFileGet } from '../json-file-get/json-file-get';
import { JsonFileSet } from '../json-file-set/json-file-set';
import { useTranslation } from '../translation/translation';
import { json, KEYS, THEMES, useStore } from '../store/store';
import { Modal } from '../modal/modal';

export const Json: FC = () => {
  const store = useStore();
  const [t] = useTranslation();
  const [value, setValue] = useState<object>();
  const [loading, setLoading] = useState(true);

  const data = store.getData();
  useEffect(() => {
    setValue(json(data));
    setLoading(false);
  }, [data]);

  return (
    <Fragment>
      <Tooltip title={t('JSON')} arrow>
        <IconButton onClick={(ev) => {
          ev.stopPropagation();
          store.dialogChange(KEYS.JSON);
        }}>
          <IntegrationInstructionsOutlined color={store.dialog(KEYS.JSON) ? 'primary' : 'inherit'} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Modal 
        style={{ pointerEvents: 'auto', minWidth: '680px' }}
        id={KEYS.JSON}
        open={!!store.dialog(KEYS.JSON)}
      >
        <List sx={{ padding: '0px' }}>
          <ListItem sx={{ padding: '8px 8px 8px 12px' }}>
            <ListItemText primary={t('JSON')} primaryTypographyProps={{ color: 'primary', variant: 'button' }} />
            <Box>
              <JsonFileGet />
              <JsonFileSet />
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.collapseChange(KEYS.JSON);
              }}>
                {!store.collapse(KEYS.JSON)
                  ? <ArrowDropDown fontSize="small" />
                  : <ArrowDropUp fontSize="small" />}
              </IconButton>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.dialogChange(KEYS.JSON)
              }}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </ListItem>
          <Collapse className="MuiDialogContent-collapse" in={!store.collapse(KEYS.JSON)} timeout="auto">
            {loading ? (
              <CircularProgress />
            ) : (
              <Box sx={{ padding: '0 8px 8px 8px' }}>
                <JsonViewer
                  value={value}
                  theme={store.theme() === THEMES.LIGHT ? THEMES.LIGHT : THEMES.DARK}
                  rootName={false}
                />
              </Box>
            )}
          </Collapse>
        </List>
      </Modal> 
    </Fragment>
  );
}
