import { ArrowDropDown, ArrowDropUp, Close, NoteAltOutlined } from '@mui/icons-material';
import { Box, Button, Collapse, Dialog, DialogContent, IconButton, List, ListItem, ListItemText, SpeedDialActionProps, TextField, Tooltip } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState, MouseEvent, Fragment } from 'react';
import { PaperComponent } from '../paper-component/paper-component';
import { useTranslation } from '../translation/translation';
import { KEYS, Data, useStore } from '../store/store';

export const Settings: FC<SpeedDialActionProps> = () => {
  const store = useStore();
  const [t] = useTranslation();
  const [data, setData] = useState<Partial<Data>>();

  const d = store.getData()
  useEffect(() => {
    setData(d);
  }, [d]);

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const name = ev.target.name;
    const value = ev.target.value;
    setData({ ...data, [name]: value });
  }

  const onClick = (ev: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    ev.stopPropagation();
    if (!data) {
      store.notification({
        type: 'error',
        text: 'Houston, we have a problem...',
      });
      return;
    }
    store.setData(data);
    store.dialogChange(KEYS.SETTINGS);
  }

  return (
    <Fragment>
      <Tooltip title={t('Settings')} arrow>
        <IconButton onClick={(ev) => {
          ev.stopPropagation();
          store.dialogChange(KEYS.SETTINGS);
        }}>
          <NoteAltOutlined color={store.dialog(KEYS.SETTINGS) ? 'primary' : 'inherit'} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog
        hideBackdrop
        disableEnforceFocus
        disableScrollLock
        style={{ pointerEvents: 'none' }}
        PaperProps={{ style: { pointerEvents: 'auto', maxWidth: '320px' }, id: KEYS.SETTINGS }}
        open={!!store.dialog(KEYS.SETTINGS)}
        PaperComponent={PaperComponent}
        >
        <DialogContent sx={{ p: 0 }}>
          <List sx={{ padding: '0px' }}>
            <ListItem sx={{ padding: '8px 8px 8px 12px' }}>
              <ListItemText primary={t('Settings')} primaryTypographyProps={{ color: 'primary', variant: 'button' }} />
              <Box>
                <IconButton size="small" onClick={(ev) => {
                  ev.stopPropagation();
                  store.collapseChange(KEYS.SETTINGS);
                }}>
                  {!store.collapse(KEYS.SETTINGS)
                    ? <ArrowDropDown fontSize="small" />
                    : <ArrowDropUp fontSize="small" />}
                </IconButton>
                <IconButton size="small" onClick={(ev) => {
                  ev.stopPropagation();
                  store.dialogChange(KEYS.SETTINGS);
                }}>
                  <Close fontSize="small" />
                </IconButton>
              </Box>
            </ListItem>
            <Collapse className="MuiDialogContent-collapse" in={!store.collapse(KEYS.SETTINGS)} timeout="auto">
              <Box sx={{ padding: '0 8px' }}>
                <TextField
                  required
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="outlined"
                  name="name"
                  label={t('Name')}
                  value={data?.name}
                  onChange={onChange}
                />
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="outlined"
                  name="description"
                  label={t('Description')}
                  value={data?.description}
                  onChange={onChange}
                />
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="outlined"
                  name="author"
                  label={t('Author')}
                  value={data?.author}
                  onChange={onChange}
                />
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="outlined"
                  name="version"
                  label={t('Version')}
                  value={data?.version}
                  onChange={onChange}
                />
              </Box>
              <Box sx={{
                padding: '8px'
              }}>
                <Button color="inherit" onClick={(ev) => {
                  ev.stopPropagation();
                  store.dialogChange(KEYS.SETTINGS);
                }}>{t('Cancel')}</Button>
                <Button onClick={onClick}>{t('Confirm')}</Button>
              </Box>
            </Collapse>
          </List>
        </DialogContent>
      </Dialog> 
    </Fragment>
  );
}