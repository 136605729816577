import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { AlertColor } from '@mui/material';
import { ElementCoil } from '../element/element-coil';
import { ElementCoilS } from '../element/element-coil-s';
import { ElementCoilN } from '../element/element-coil-n';
import { ElementCoilR } from '../element/element-coil-r';
import { ElementCntu } from '../element/element-cntu';
import { ElementCntd } from '../element/element-cntd';
import { ElementCntr } from '../element/element-cntr';
import { ElementAdd } from '../element/element-add';
import { ElementDiv } from '../element/element-div';
import { ElementMul } from '../element/element-mul';
import { ElementSub } from '../element/element-sub';
import { ElementNo } from '../element/element-no';
import { ElementNc } from '../element/element-nc';
import { ElementNf } from '../element/element-nf';
import { ElementPf } from '../element/element-pf';
import { ElementGt } from '../element/element-gt';
import { ElementGe } from '../element/element-ge';
import { ElementEq } from '../element/element-eq';
import { ElementLe } from '../element/element-le';
import { ElementLt } from '../element/element-lt';
import { ElementNq } from '../element/element-nq';
import { ElementMov } from '../element/element-mov';
import { ElementTof } from '../element/element-tof';
import { ElementTon } from '../element/element-ton';
import { ElementTres } from '../element/element-tres';

const { version } = require('../../package.json');

export enum KEYS {
  JSON = 'json',
  AUTH = 'auth',
  WIFI = 'wifi',
  
  DEBUG = 'debug',

  LOGOUT_CONFIRM = 'logout-confirm',

  PASSWORD = 'password',
  SETTINGS = 'settings',
  TAGS = 'tags',
  LOGS = 'logs',
  VARIABLES = 'variables',
  EXAMPLES = 'examples',

  

  // NETWORK = 'network',
  // NETWORK_CREATE = 'network-create',
  // NETWORK_UPDATE = 'network-update',
  // NETWORK_REMOVE = 'network-remove',

  // PROGRAM_LOGS = 'terminal-logs',
  // PROGRAM_SCENARIOS = 'program-scenarios',
  // PROGRAM_VARIABLES = 'program-variables',

  // PROGRAM_CREATE = 'program-create',
  // PROGRAM_UPDATE = 'program-update',
  // PROGRAM_REMOVE = 'program-remove',
  PROGRAMS = 'programs',
  // PROGRAM = 'program',

  INSTRUCTIONS = 'instructions',
  INSTRUCTION = 'instruction',
  INSTRUCTION_CREATE = 'instruction-create',
  INSTRUCTION_UPDATE = 'instruction-update',
  INSTRUCTION_REMOVE = 'instruction-remove',

  RUNG = 'rung',
  RUNG_CREATE = 'rung-create',
  RUNG_UPDATE = 'rung-update',
  RUNG_REMOVE = 'rung-remove',

  ELEMENT_UPDATE = 'element-update',
  ELEMENT_REMOVE = 'element-remove',
}

export enum DATA {
  IN = 'in',
  OUT = 'out',
  NUM = 'num',
  PRESET = 'preset',
  SRC_A = 'src_a',
  SRC_B = 'src_b',
  DEST = 'dest',
}

export enum ELEMENT_TYPES {
  NO = 'NO',
  NC = 'NC',
  PF = 'PF',
  NF = 'NF',
  COIL = 'COIL',
  COIL_N = 'COIL_N',
  COIL_S = 'COIL_S',
  COIL_R = 'COIL_R',
  TON = 'TON',
  TOF = 'TOF',
  TRES = 'TRES',
  ADD = 'ADD',
  SUB = 'SUB',
  MUL = 'MUL',
  DIV = 'DIV',
  MOV = 'MOV',
  EQ = 'EQ',
  NQ = 'NQ',
  GT = 'GT',
  LT = 'LT',
  GE = 'GE',
  LE = 'LE',
  CNTU = 'CNTU',
  CNTD = 'CNTD',
  CNTR = 'CNTR',

  // MODB = 'MODB',
  // DSHB = 'DSHB',

  // AND = 'AND',
  // NOT = 'NOT',
  // OR = 'OR',
  // XOR = 'XOR',
  // MODB = 'MODB',
  // HTTP = 'HTTP',
}

export enum LANGUAGES {
  EN = 'en',
  UA = 'ua',
}

export const PROGRAM_ELEMENTS = {
  [ELEMENT_TYPES.NO]: ElementNo,
  [ELEMENT_TYPES.NC]: ElementNc,
  [ELEMENT_TYPES.PF]: ElementPf,
  [ELEMENT_TYPES.NF]: ElementNf,
  [ELEMENT_TYPES.COIL]: ElementCoil,
  [ELEMENT_TYPES.COIL_N]: ElementCoilN,
  [ELEMENT_TYPES.COIL_S]: ElementCoilS,
  [ELEMENT_TYPES.COIL_R]: ElementCoilR,
  [ELEMENT_TYPES.TON]: ElementTon,
  [ELEMENT_TYPES.TOF]: ElementTof,
  [ELEMENT_TYPES.TRES]: ElementTres,
  [ELEMENT_TYPES.ADD]: ElementAdd,
  [ELEMENT_TYPES.SUB]: ElementSub,
  [ELEMENT_TYPES.MUL]: ElementMul,
  [ELEMENT_TYPES.DIV]: ElementDiv,
  [ELEMENT_TYPES.MOV]: ElementMov,
  [ELEMENT_TYPES.EQ]: ElementEq,
  [ELEMENT_TYPES.NQ]: ElementNq,
  [ELEMENT_TYPES.GT]: ElementGt,
  [ELEMENT_TYPES.LT]: ElementLt,
  [ELEMENT_TYPES.GE]: ElementGe,
  [ELEMENT_TYPES.LE]: ElementLe,
  [ELEMENT_TYPES.CNTU]: ElementCntu,
  [ELEMENT_TYPES.CNTD]: ElementCntd,
  [ELEMENT_TYPES.CNTR]: ElementCntr,
};

export enum VARIABLE_TYPES {
  DINT = 'DINT',
  BOOL = 'BOOL',
  LREAL = 'LREAL',
}

export enum VARIABLES {
  I = 'I',
  Q = 'Q',
  M = 'M',
  MI = 'MI',
  T = 'T',
  CW = 'CW',
  MW = 'MW',
  AI = 'AI',
  MF = 'MF',
  C = 'C',
  CF = 'CF',
  AQ = 'AQ',
}

export type Scenario = {
  id: string;
  name: string;
  description: string;
  active?: boolean;
} & Record<string, string | boolean | number>;

export interface Tag {
  id: string;
  name: string;
  description?: string;
  address: string;
} 

export interface Variable {
  id: string;
  name: string;
  description?: string;
  type: VARIABLE_TYPES;
  value: number | boolean;
  retain: boolean;
}

export interface Element {
  id: string;
  type: ELEMENT_TYPES;
  name?: string;
  description?: string;
  data?: {
    [key in DATA]?: string;
  };
}

export type Parallel<T> = T | {
  id: string
  elements: [Array<Parallel<T>>, Array<Parallel<T>>]
}

export interface Comment {
  id: string;
  text: string;
  createdAt: Date,
  updatedAt?: Date,
}

export interface Rung {
  id: string;
  name: string;
  description?: string;
  elements: Array<Parallel<Element> | Element>;
}

export interface Program {
  id: string;
  name: string;
  description?: string;
  rungs: Array<Rung>;
  variables: Array<Variable>;
  scenarios?: Array<Scenario>;
  logs?: Array<string>;
}

export interface Data {
  name: string;
  description?: string;
  author?: string;
  version?: string;
  connection: string;
  dataTime: string;
  fwVersion: string;
  hwVersion: string;
  wifiIp: string;
  wifiSsid: string;
  wifiMode: string;
  wifiStatus: string;
  wifiPassword: string;
  ethernetIp: string;
  ethernetMode: string;
  ethernetStatus: string;
  tags: Array<Tag>;
  programs: Array<Program>;
}

export interface Position {
  x: number;
  y: number;
}

export enum THEMES {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum STATUS_CODES {
  RUN = 'RUN',
  STOP = 'STOP',
  NOT_INITED = 'NOT_INITED'
}

export interface Notification {
  id: string;
  text: string;
  type: AlertColor;
}

interface Item {
  type: ELEMENT_TYPES;
  name: string;
  description: string;
}

interface Instruction {
  name: string;
  items: Array<Item>
}

type Parameter = Partial<{
  [k in DATA]: Array<VARIABLES>;
}>

const notifications: Notification[] = [{
  id: `${Math.floor(Math.random() * Date.now())}`,
  type: 'info',
  text: 'Welcome to FET Devices, where controllers matter',
}];

const data: Data = {
  name: 'FET devices',
  description: '',
  version: '',
  connection: `http://${window.location.host}/ladder_program`,

  dataTime: new Date().toISOString(),

  fwVersion: '',
  hwVersion: '',

  wifiIp: '',
  wifiSsid: '',
  wifiMode: 'On',
  wifiStatus: 'Connected',
  wifiPassword: '',
  
  ethernetIp: '',
  ethernetMode: 'On',
  ethernetStatus: 'Disconnected',

  tags: [],
  programs: [{
    id: `${Math.floor(Math.random() * Date.now())}`,
    name: 'PROGRAM',
    description: 'PLC program',
    variables: [
      ...new Array(2).fill(null).map((_, i) => ({
        id: `${Math.floor(Math.random() * Date.now())}`,
        name: `variable ${i+1}`,
        type: VARIABLE_TYPES.DINT,
        value: 1,
        retain: false,
      })),
      ...new Array(2).fill(null).map((_, i) => ({
        id: `${Math.floor(Math.random() * Date.now())}`,
        name: `variable ${i+1+2}`,
        type: VARIABLE_TYPES.LREAL,
        value: 0.1,
        retain: false,
      })),
      ...new Array(2).fill(null).map((_, i) => ({
        id: `${Math.floor(Math.random() * Date.now())}`,
        name: `variable ${i+1+4}`,
        type: VARIABLE_TYPES.BOOL,
        value: true,
        retain: false,
      }))
    ],
    rungs: [{
      id: `${Math.floor(Math.random() * Date.now())}`,
      name: 'Rung 1',
      description: '',
      elements: [],
    }],
  }]
};

const parameters: {[k in ELEMENT_TYPES]: Partial<{ [k in DATA]: Array<VARIABLES> }>} = {
  [ELEMENT_TYPES.NO]: {
    [DATA.IN]: [VARIABLES.I, VARIABLES.Q, VARIABLES.M],
  },
  [ELEMENT_TYPES.NC]: {
    [DATA.IN]: [VARIABLES.I, VARIABLES.Q, VARIABLES.M],
  },
  [ELEMENT_TYPES.PF]: {
    [DATA.IN]: [VARIABLES.I, VARIABLES.Q, VARIABLES.M],
  },
  [ELEMENT_TYPES.NF]: {
    [DATA.IN]: [VARIABLES.I, VARIABLES.Q, VARIABLES.M],
  },
  [ELEMENT_TYPES.COIL]: {
    [DATA.OUT]: [VARIABLES.Q, VARIABLES.M]
  },
  [ELEMENT_TYPES.COIL_N]: {
    [DATA.OUT]: [VARIABLES.Q, VARIABLES.M]
  },
  [ELEMENT_TYPES.COIL_S]: {
    [DATA.OUT]: [VARIABLES.Q, VARIABLES.M]
  },
  [ELEMENT_TYPES.COIL_R]: {
    [DATA.OUT]: [VARIABLES.Q, VARIABLES.M]
  },
  [ELEMENT_TYPES.TON]: {
    [DATA.NUM]: [VARIABLES.T],
    [DATA.PRESET]: [VARIABLES.CW, VARIABLES.MW, VARIABLES.AI],
  },
  [ELEMENT_TYPES.TOF]: {
    [DATA.NUM]: [VARIABLES.T],
    [DATA.PRESET]: [VARIABLES.CW, VARIABLES.MW, VARIABLES.AI],
  },
  [ELEMENT_TYPES.TRES]: {
    [DATA.NUM]: [VARIABLES.T],
  },
  [ELEMENT_TYPES.ADD]: {
    [DATA.SRC_A]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.C, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.SRC_B]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.C, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.DEST]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.AQ],
  },
  [ELEMENT_TYPES.SUB]: {
    [DATA.SRC_A]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.C, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.SRC_B]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.C, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.DEST]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.AQ],
  },
  [ELEMENT_TYPES.MUL]: {
    [DATA.SRC_A]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.C, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.SRC_B]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.C, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.DEST]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.AQ],
  },
  [ELEMENT_TYPES.DIV]: {
    [DATA.SRC_A]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.C, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.SRC_B]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.C, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.DEST]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.AQ],
  },
  [ELEMENT_TYPES.MOV]: {
    [DATA.SRC_A]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.C, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.DEST]: [VARIABLES.M, VARIABLES.MW, VARIABLES.MF, VARIABLES.AQ],
  },
  [ELEMENT_TYPES.EQ]: {
    [DATA.SRC_A]: [VARIABLES.MW, VARIABLES.MF, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.SRC_B]: [VARIABLES.MW, VARIABLES.MF, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
  },
  [ELEMENT_TYPES.NQ]: {
    [DATA.SRC_A]: [VARIABLES.MW, VARIABLES.MF, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.SRC_B]: [VARIABLES.MW, VARIABLES.MF, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
  },
  [ELEMENT_TYPES.GT]: {
    [DATA.SRC_A]: [VARIABLES.MW, VARIABLES.MF, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.SRC_B]: [VARIABLES.MW, VARIABLES.MF, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
  },
  [ELEMENT_TYPES.LT]: {
    [DATA.SRC_A]: [VARIABLES.MW, VARIABLES.MF, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.SRC_B]: [VARIABLES.MW, VARIABLES.MF, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
  },
  [ELEMENT_TYPES.GE]: {
    [DATA.SRC_A]: [VARIABLES.MW, VARIABLES.MF, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.SRC_B]: [VARIABLES.MW, VARIABLES.MF, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
  },
  [ELEMENT_TYPES.LE]: {
    [DATA.SRC_A]: [VARIABLES.MW, VARIABLES.MF, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
    [DATA.SRC_B]: [VARIABLES.MW, VARIABLES.MF, VARIABLES.CW, VARIABLES.CF, VARIABLES.AI, VARIABLES.AQ],
  },
  [ELEMENT_TYPES.CNTU]: {
    [DATA.NUM]: [VARIABLES.C],
    [DATA.PRESET]: [VARIABLES.CW, VARIABLES.MW, VARIABLES.AI],
  },
  [ELEMENT_TYPES.CNTD]: {
    [DATA.NUM]: [VARIABLES.C],
    [DATA.PRESET]: [VARIABLES.CW, VARIABLES.MW, VARIABLES.AI],
  },
  [ELEMENT_TYPES.CNTR]: {
    [DATA.NUM]: [VARIABLES.C],
  },
};

export const instructions: Instruction[] = [
  {
    name: 'Bit logic operations',
    items: [
      {
        name: 'Normally open contact',
        description: '',
        type: ELEMENT_TYPES.NO,
      },
      {
        name: 'Normally closed contact',
        description: '',
        type: ELEMENT_TYPES.NC,
      },
      {
        name: 'Positive edge detection',
        description: '',
        type: ELEMENT_TYPES.PF,
      },
      {
        name: 'Negative edge detection',
        description: '',
        type: ELEMENT_TYPES.NF,
      },
      {
        name: 'Assignment',
        description: '',
        type: ELEMENT_TYPES.COIL,
      },
      {
        name: 'Negate assignment',
        description: '',
        type: ELEMENT_TYPES.COIL_N,
      },
      {
        name: 'Set output',
        description: '',
        type: ELEMENT_TYPES.COIL_S,
      },
      {
        name: 'Reset output',
        description: '',
        type: ELEMENT_TYPES.COIL_R,
      },
    ]
  },
  {
    name: 'Timer operations',
    items: [
      {
        name: 'Generate on-delay',
        description: '',
        type: ELEMENT_TYPES.TON,
      },
      {
        name: 'Generate off-delay',
        description: '',
        type: ELEMENT_TYPES.TOF,
      },
      {
        name: 'Reset timer',
        description: '',
        type: ELEMENT_TYPES.TRES,
      },
    ]
  },
  {
    name: 'Counter operations',
    items: [
      {
        name: 'Count up',
        description: '',
        type: ELEMENT_TYPES.CNTU,
      },
      {
        name: 'Count down',
        description: '',
        type: ELEMENT_TYPES.CNTD,
      },
      {
        name: 'Count reset',
        description: '',
        type: ELEMENT_TYPES.CNTR,
      },
    ]
  },
  {
    name: 'Comparator operations',
    items: [
      {
        name: 'Equal',
        description: '',
        type: ELEMENT_TYPES.EQ,
      },
      {
        name: 'Not equal',
        description: '',
        type: ELEMENT_TYPES.NQ,
      },
      {
        name: 'Greater or equal',
        description: '',
        type: ELEMENT_TYPES.GE,
      },
      {
        name: 'Less or equal',
        description: '',
        type: ELEMENT_TYPES.LE,
      },
      {
        name: 'Greater than',
        description: '',
        type: ELEMENT_TYPES.GT,
      },
      {
        name: 'Less than',
        description: '',
        type: ELEMENT_TYPES.LT,
      },
    ]
  },
  {
    name: 'Math functions',
    items: [
      {
        name: 'Add',
        description: '',
        type: ELEMENT_TYPES.ADD,
      },
      {
        name: 'Subtract',
        description: '',
        type: ELEMENT_TYPES.SUB,
      },
      {
        name: 'Multiply',
        description: '',
        type: ELEMENT_TYPES.MUL,
      },
      {
        name: 'Divide',
        description: '',
        type: ELEMENT_TYPES.DIV,
      },
    ]
  },
  {
    name: 'Move operations',
    items: [
      {
        name: 'Move value',
        description: '',
        type: ELEMENT_TYPES.MOV,
      },
    ]
  },
  // {
  //   name: 'Conversion operations',
  //   items: []
  // },
  // {
  //   name: 'Custom',
  //   items: []
  // },
];

const elementFind = (id: string, elements: Array<Parallel<Element>>): Element | null => {
  return elements.reduce<Element | null>((accumulator, current) => {
    if (accumulator) {
      return accumulator;
    }
    if (current.id === id) {
      return current as Element;
    }
    if ('elements' in current) {
      return elementFind(id, current.elements[0]) || elementFind(id, current.elements[1]);
    }
    return accumulator;
  }, null);
}

const elementRemove = (id: string, elements: Array<Parallel<Element>>): Array<Parallel<Element>> => {
  return elements.reduce<Array<Parallel<Element>>>((accumulator, current) => {
    if ('elements' in current) {
      const a = elementRemove(id, current.elements[0]);
      const b = elementRemove(id, current.elements[1]);

      if (a.length === 0 || b.length === 0) {
        a.forEach((i) => {
          accumulator.push(i);
        });
        b.forEach((i) => {
          accumulator.push(i);
        });
        return accumulator; 
      }

      current.elements[0] = a;
      current.elements[1] = b;

      accumulator.push(current);
      return accumulator;
    }
    if (current.id !== id) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
}

const elementUpdate = (id: string | null, elements:  Array<Parallel<Element>>, data: Partial<Element>): Array<Parallel<Element>> => {
  return elements.reduce<Array<Parallel<Element>>>((accumulator, current) => {
    if ('elements' in current) {
      current.elements[0] = elementUpdate(id, current.elements[0], data);
      current.elements[1] = elementUpdate(id, current.elements[1], data);

      accumulator.push(current);
      return accumulator;
    }
    if (current.id === id) {
      accumulator.push({
        ...current,
        ...data,
      });
    } else {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
}

const dragRemove = (
  element:  Element | Pick<Element, 'id' | 'type'>,
  elements: Array<Parallel<Element>>,
): Array<Parallel<Element>> => {
  if (!element.id) {
    return elements;  
  }
  return elementRemove(element.id, elements);
}

const dragUpsert = (
  element:  Element | Pick<Element, 'id' | 'type'>,
  elements: Array<Parallel<Element>>,
  relations: Array<Parallel<Element> | null>,
): Array<Parallel<Element>> => {
  const id = element.id ? element.id : `${Math.floor(Math.random() * Date.now())}`;
  const type = element.type;
  const name = 'name' in element ? element.name : element.type;
  const description = 'description' in element ? element.description : '';
  const data = 'data' in element ? element.data : {};

  if (!elements.length) {
    console.log('0')
    return [{ id, type, name, data, description }];
  }

  const a = relations[0];
  const b = relations[1];

  return elements.reduce<Parallel<Element>[]>((accumulator, current) => {
    if (current.id === a?.id) {
      console.log('1')
      accumulator.push(current);
      accumulator.push({ id, type, name, data, description });
      return accumulator;
    }
    if (current.id === b?.id) {
      console.log('2')
      accumulator.push({ id, type, name, data, description });
      accumulator.push(current);
      return accumulator;
    }
    if ('elements' in current) {
      console.log('3')
      current.elements[0] = dragUpsert(element, current.elements[0], relations);
      current.elements[1] = dragUpsert(element, current.elements[1], relations);

      accumulator.push(current);
      return accumulator;
    }
    if (current.id !== id) {
      console.log('4')
      accumulator.push(current);
      return accumulator;
    }
    console.log('-'.repeat(100))
    return accumulator;
  }, []);
}

const dragInsert = (
  element:  Element | Pick<Element, 'id' | 'type'>,
  elements: Array<Parallel<Element>>,
  relation: string,
) => {
  const id = element.id ? element.id : `${Math.floor(Math.random() * Date.now())}`;
  const type = element.type;
  const name = 'name' in element ? element.name : element.type;
  const description = 'description' in element ? element.description : '';
  const data = 'data' in element ? element.data : {};
  return elements.reduce<Parallel<Element>[]>((accumulator, current) => {
    if ('elements' in current) {
      current.elements[0] = dragInsert(element, current.elements[0], relation);
      current.elements[1] = dragInsert(element, current.elements[1], relation);

      accumulator.push(current);
      return accumulator;
    }
    if (current.id === relation) {
      accumulator.push({
        id: `${Math.floor(Math.random() * Date.now())}`,
        elements: [[current], [{ id, type, name, description, data }]],
      });
    } else {
      accumulator.push(current);
      return accumulator;
    }
    return accumulator;
  }, []);
}

export const instruction = (type: any): any => {
  return instructions.find(
    (i) => !!i.items.find((i) => i.type === type)
  );
}

export const find = (type: any): any =>{
  const items = instructions.reduce<Array<Item>>((a, c) => {
    c.items.forEach((i) => {
      a.push(i);
    });
    return a;
  }, []);
  return items.find((i) => i.type === type);
}

const sha = process.env.REACT_APP_GITHUB_SHA?.slice(0, 6) || '';
const tabs = [data.programs[0]['id']];
const theme = THEMES.LIGHT;
const language = LANGUAGES.UA;
const drawer = true;
const dialog = {};
const collapse = {};
const position = {};

interface Value {
  sha: string;
  theme: THEMES;
  drawer: boolean;
  tabs: Array<string>
  dialog: { [k: KEYS | string]: boolean };
  collapse: { [k: KEYS | string]: boolean };
  position: { [k: KEYS | string]: Position };

  notifications: Array<Notification>,
  data: Data;
  parameters: {[k in ELEMENT_TYPES]: Parameter};
  language?: LANGUAGES;
  status?: STATUS_CODES;
  token?: string;
}

interface Relations {
  a?: string;
  b?: string;
  rung?: string; 
}

export interface State {
  value: Value;

  options: (program: string, type: ELEMENT_TYPES, data: DATA) => Array<{ id: string, label: string }>;

  getValue: () => Value;
  setValue: (data: Partial<Value>) => void;

  getData: () => Data;
  setData: (data: Partial<Data>) => void;

  sha: () => string;

  tabs: () => Array<string>;
  tabUpsert: (id: string) => void;
  tabRemove: (id: string) => void;

  token: () => string | undefined;
  tokenChange: (token?: string) => void;
 
  status: () => STATUS_CODES | undefined;
  statusChange: (status: STATUS_CODES) => void;

  dialog: (key: string | Array<string>) => boolean;
  dialogChange: (key: string | Array<string>) => void;

  collapse: (key: string | Array<string>) => boolean;
  collapseChange: (key: string | Array<string>) => void;

  position: (key: string | Array<string|undefined>) => Position;
  positionChange: (key: string | Array<string|undefined>, data: Position) => void;

  drawer: () => boolean;
  drawerChange: () => void;

  theme: () => THEMES;
  themeChange: () => void;

  language: () => LANGUAGES;
  changeLanguage: (key: LANGUAGES) => void;

  parameters: () => {[k in ELEMENT_TYPES]: Parameter};
  parameter: (type: ELEMENT_TYPES) => Parameter;

  tagCreate: (data: Omit<Tag, 'id'>) => void;
  tagUpdate: (id: string, data: Partial<Tag>) => void;
  tagRemove: (id: string) => void;
  tags: () => Array<Tag>;

  notification: (data: Omit<Notification, 'id'>) => void;
  notifications: () => Array<Notification>;
  notificationUpdate: (id: string, data: Partial<Notification>) => void;
  notificationRemove: (id: string) => void;

  program: (id: string) => Program;
  programs: () => Program[];
  programUpdate: (id: string, data: Partial<Program>) => void;

  rung: (id: string) => Rung;
  rungs: (program: string) => Array<Rung>;
  rungCreate: (program: string, data: Omit<Rung, 'id' | 'elements'>) => void;
  rungUpdate: (id: string, data: Partial<Rung>) => void;
  rungRemove: (id: string) => void;

  rungUp: (program: string, id: string) => void;
  rungDown: (program: string, id: string) => void;

  element: (id: string) => Element;
  elementUpdate: (id: string, data: Partial<Element>) => void;
  elementRemove: (id: string) => void;
  elementInsert: (id: string, data: Pick<Element, 'id' | 'type'>) => void;
  elementUpsert: (relations: Relations, data: Pick<Element, 'id' | 'type'>) => void;
}

export const useStore = create<State>()(
  persist<State, [], [], State>((set, get) => ({
    value: { language, tabs, sha, theme, drawer, dialog, collapse, position, data, parameters, notifications },
    token: () => {
      const state = get();
      return state.value.token;
    },
    tokenChange: (token) => {
      const state = get();
      state.setValue({ token });
    },
   
    language: () => {
      const state = get();
      return state.value.language || language;
    },
    changeLanguage: (language) => {
      const state = get();
      state.setValue({ language });
    },

    options: (program, type, data) => {
      const state = get();

      const parameters = state.parameter(type);
      const tags = state.tags();
      const p = state.program(program);

      const variables = p.variables.reduce((accumulator, variable) => {
        const type = variable.type.toUpperCase();
        if (!accumulator[type]) {
          accumulator[type] = [];
        }
        accumulator[type].push(variable);
        return accumulator;
      }, {} as any);

      const elements = p.rungs.flat().map((rung) => rung.elements).flat();
      const t: any[] = tRecursion(elements);
      const c: any[] = cRecursion(elements);

      const options: any[] = [];

      Array.from(Array(12).keys()).forEach((_, i) => {
        if (parameters[data]?.includes(VARIABLES.I)) {
          options.push({ label: `I${i}`, id: `I${i}` });
        }
        if (parameters[data]?.includes(VARIABLES.AI)) {
          options.push({ label: `AI${i}`, id: `AI${i}` });
        }
        if (parameters[data]?.includes(VARIABLES.Q)) {
          options.push({ label: `Q${i}`, id: `Q${i}` });
        }
        if (parameters[data]?.includes(VARIABLES.AQ)) {
          options.push({ label: `AQ${i}`, id: `AQ${i}` });
        }
      });

      tags.filter((i) => i.address).forEach((tag) => {
        if (
          tag.address.includes(VARIABLES.I) ||
          tag.address.includes(VARIABLES.AI) ||
          tag.address.includes(VARIABLES.Q) ||
          tag.address.includes(VARIABLES.AQ) ||
          tag.address.includes(VARIABLES.M) ||
          tag.address.includes(VARIABLES.T)
        ) {
          options.push({ label: tag.name || tag.address, id: tag.address });
        }
      });

      variables[VARIABLE_TYPES.BOOL]?.forEach((variable: any, i: number) => {
        if (parameters[data]?.includes(VARIABLES.M)) {
          options.push({ label: variable.name || `M${i}`, id: `M${i}` });
        }
      });

      variables[VARIABLE_TYPES.DINT]?.forEach((variable: any, i: number) => {
        if (parameters[data]?.includes(VARIABLES.MW)) {
          options.push({ label: variable.name || `MW${i}`, id: `MW${i}` });
        }
      });

      variables[VARIABLE_TYPES.LREAL]?.forEach((variable: any, i: number) => {
        if (parameters[data]?.includes(VARIABLES.MF)) {
          options.push({ label: variable.name || `MF${i}`, id: `MF${i}` });
        }
      });

      if (
        [
          ELEMENT_TYPES.TON,
          ELEMENT_TYPES.TOF,
          ELEMENT_TYPES.TRES,
        ].includes(type) && data !== DATA.PRESET
      ) {
        t.forEach((_, i) => {
          options.push({ label: `T${i}`, id: `T${i}` });
          // options.push(`T${i}`);
        })
      }

      if (
        [
          ELEMENT_TYPES.CNTU,
          ELEMENT_TYPES.CNTD,
          ELEMENT_TYPES.CNTR,
        ].includes(type) && data !== DATA.PRESET
      ) {
        c.forEach((_, i) => {
          options.push({ label: `C${i}`, id: `C${i}` });
          // options.push(`C${i}`);
        })
      }

      if (
        [
          ELEMENT_TYPES.ADD,
          ELEMENT_TYPES.SUB,
          ELEMENT_TYPES.MUL,
          ELEMENT_TYPES.DIV,
          ELEMENT_TYPES.MOV,
          ELEMENT_TYPES.EQ,
          ELEMENT_TYPES.NQ,
          ELEMENT_TYPES.GT,
          ELEMENT_TYPES.LT,
          ELEMENT_TYPES.GE,
          ELEMENT_TYPES.LE,
        ].includes(type)
      ) {
        t.forEach((_, i) => {
          options.push({ label: `T${i}:ET`, id: `T${i}:ET` });
          // options.push(`T${i}:ET`);
        })
        c.forEach((_, i) => {
          options.push({ label: `C${i}:CV`, id: `C${i}:CV` });
          // options.push(`C${i}:CV`);
        })
      }

      return options;
    },

    getValue: () => {
      const state = get();
      return state.value;
    },
    setValue: (data: Partial<Value>) => set((state) => {
      const value = { ...state.value, ...data };
      return { value };
    }),
  
    getData: () => {
      const state = get();
      return state.value.data;
    },
    setData: (data) => {
      const state = get();
      state.setValue({ data: { ...state.value.data, ...data } });
    },

    tabs: () => {
      const state = get();
      return state.value.tabs;
    },
    tabUpsert: (id) => {
      const state = get();
      const tabs = state.value.tabs.includes(id)
        ? state.value.tabs
        : [...state.value.tabs, id];
      state.setValue({ tabs });
    },
    tabRemove: (id: string) => {
      const state = get();
      const tabs = state.value.tabs.filter((i) => i !== id);
      state.setValue({ tabs });
    },
    sha: () => {
      const state = get();
      return state.value.sha;
    },

    parameters: () => {
      const state = get();
      return state.value.parameters;
    },
    parameter: (type: ELEMENT_TYPES) => {
      const state = get();
      const parameters = state.value.parameters;
      return parameters[type];
    },

    status: () => {
      const state = get();
      return state.value.status;
    },
    statusChange: (status) => {
      const state = get();
      state.setValue({ status });
    },

    theme: () => {
      const state = get();
      return state.value.theme;
    },
    themeChange: () => {
      const state = get();
      const theme = state.value.theme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;
      state.setValue({ theme });
    },
    drawer: () => {
      const state = get();
      return state.value.drawer;
    },
    drawerChange: () => {
      const state = get();
      const drawer = !state.value.drawer;
      state.setValue({ drawer });
    },

    dialog: (key) => {
      if (Array.isArray(key)) {
        key = key.join('-');
      }
      const state = get();
      return state.value.dialog[key];
    },
    dialogChange: (key) => {
      const state = get();
      if (!key) {
        state.notification({
          type: 'error',
          text: 'Information Unavailable',
        });
        throw new Error('...');
      }
      if (Array.isArray(key)) {
        key = key.join('-');
      }
      const dialog = { ...state.value.dialog, [key]: !state.value.dialog[key] };
      state.setValue({ dialog });
    },
    collapse: (key) => {
      if (Array.isArray(key)) {
        key = key.join('-');
      }
      const state = get();
      return state.value.collapse[key];
    },
    collapseChange: (key) => {
      const state = get();
      if (!key) {
        state.notification({
          type: 'error',
          text: 'Information Unavailable',
        });
        throw new Error('...');
      }
      if (Array.isArray(key)) {
        key = key.join('-');
      }
      const collapse = { ...state.value.collapse, [key]: !state.value.collapse[key] };
      state.setValue({ collapse });
    },
    position: (key) => {
      if (Array.isArray(key)) {
        key = key.join('-');
      }
      const state = get();
      return state.value.position[key];
    },
    positionChange: (key, data) => {
      const state = get();
      if (!key) {
        state.notification({
          type: 'error',
          text: 'Information Unavailable',
        });
        throw new Error('...');
      }
      if (Array.isArray(key)) {
        key = key.join('-');
      }
      const position = { ...state.value.position, [key]: data };
      state.setValue({ position });
    },

    tagCreate: (data) => {
      const state = get();
      const tags = [...state.value.data.tags, {
        ...data,
        id: `${Math.floor(Math.random() * Date.now())}`,
      }];
      state.setData({ tags });
    },
    tagUpdate: (id, data) => {
      const state = get();
      const tags = state.value.data.tags.map(
        (i) => i.id === id ? { ...i, ...data } : i
      );
      state.setData({ tags });
    },
    tagRemove: (id) => {
      const state = get();
      const tags = state.value.data.tags.filter(
        (i) => i.id !== id
      );
      state.setData({ tags });
    },
    tags: () => {
      const state = get();
      return state.value.data.tags;
    },

    notification: (data) => {
      const state = get();
      const notification = state.value.notifications.find(
        (i) => i.text === data.text
      );
      if (notification) {
        return;
      }
      const notifications = [...state.value.notifications, {
        ...data,
        id: `${Math.floor(Math.random() * Date.now())}`,
      }];
      state.setValue({ notifications });
    },
    notifications: () => {
      const state = get();
      return state.value.notifications;
    },
    notificationUpdate: (id, data) => {
      const state = get();
      const notifications = state.value.notifications.map(
        (i) => i.id === id ? { ...i, ...data } : i
      );
      state.setValue({ notifications });
    },
    notificationRemove: (id) => {
      const state = get();
      const notifications = state.value.notifications.filter(
        (i) => i.id !== id
      );
      state.setValue({ notifications });
    },

    program: (id) => {
      const state = get();
      const program = state.value.data.programs.find(
        (i) => i.id === id
      );

      if (!program) {
        state.notification({
          type: 'error',
          text: 'Information Unavailable',
        });
        throw new Error('...');
      }
      return program; 
    },
    programs: () => {
      const state = get();
      return state.value.data.programs;
    },
    programUpdate: (id, data) => {
      const state = get();
      const programs = state.value.data.programs.map(
        (i) => i.id === id ? { ...i, ...data } : i
      );
      state.setData({ programs });
    },
    rung: (id) => {
      const state = get();
      const program = state.value.data.programs[0];
      const rung = program.rungs.flat().find((i) => i.id === id);
      if (!rung) {
        state.notification({
          type: 'error',
          text: 'Information Unavailable',
        });
        throw new Error('...');
      }
      return rung; 
    },
    rungs: (program) => {
      const state = get();
      const p = state.value.data.programs.find((i) => i.id === program);
      if (!p) {
        state.notification({
          type: 'error',
          text: 'Information Unavailable',
        });
        throw new Error('...');
      }
      return p.rungs;
    },
    rungRemove: (id) => {
      const state = get();
      const programs = state.value.data.programs.map((i) => {
        const rungs = i.rungs.filter((i) => i.id !== id);
        return { ...i, rungs };
      });
      state.setData({ programs });
    },
    rungCreate: (program, data) => {
      const state = get();
      const programs = state.value.data.programs.map((i) => {
        if (i.id !== program) {
          return i;
        }
        const rung = {
          ...data,
          id: `${Math.floor(Math.random() * Date.now())}`,
          elements: [],
        };
        const rungs = [...i.rungs, rung];
        return { ...i, rungs };
      });
      state.setData({ programs });
    },
    rungUpdate: (id, data) => {
      const state = get();
      const programs = state.value.data.programs.map((i) => {
        const rungs = i.rungs.map((i) => i.id === id ? { ...i, ...data } : i);
        return { ...i, rungs };
      });
      state.setData({ programs });
    },

    rungUp: (program, id) => {
      const state = get();
      const programs = state.value.data.programs.map((i) => {
        if (i.id !== program) {
          return i;
        }
        const rungs = i.rungs;
        const index =rungs.findIndex((i) => i.id === id);
        rungs.splice(index-1, 0, ...rungs.splice(index, 1));
        return { ...i, rungs };
      });
      state.setData({ programs });
    },
    rungDown: (program, id) => {
      const state = get();
      const programs = state.value.data.programs.map((i) => {
        if (i.id !== program) {
          return i;
        }
        const rungs = i.rungs;
        const index = rungs.findIndex((i) => i.id === id);
        rungs.splice(index+1, 0, ...rungs.splice(index, 1));
        return { ...i, rungs };
      });
      state.setData({ programs });
    },

    element: (id) => {
      const state = get();
      const element = state.value.data.programs
        .map((i) => i.rungs)
        .flat()
        .reduce<Element|null>((a, c) => a || elementFind(id, c.elements), null);
      if (!element) {
        state.notification({
          type: 'error',
          text: 'Information Unavailable',
        });
        throw new Error('...');
      }
      return element;
    },
    elementUpdate: (id, data) => {
      const state = get();
      const programs = state.value.data.programs.map((i) => {
        const rungs = i.rungs.map((i) => {
          const elements = elementUpdate(id, i.elements, data);
          return { ...i, elements };
        });
        return { ...i, rungs };
      });
      state.setData({ programs });
    },
    elementRemove: (id) => {
      const state = get();
      const programs = state.value.data.programs.map((i) => {
        const rungs = i.rungs.map((i) => {
          const elements = elementRemove(id, i.elements)
          return { ...i, elements };
        });
        return { ...i, rungs };
      });
      state.setData({ programs });
    },
    elementInsert: (id, data) => {
      if (data.id === id) {
        return;
      }
      const state = get();
      const element = data.id
        ? state.element(data.id)
        : data;
      const programs = state.value.data.programs.map((i) => {
        const rungs = i.rungs.map((i) => {
          const elements = dragRemove(element, i.elements);
          return { ...i, elements };
        }).map((i) => {
          const relation = id; 
          const elements = dragInsert(element, i.elements, relation);
          return { ...i, elements };
        });
        return { ...i, rungs };
      });
      state.setData({ programs });
    },
    elementUpsert: (relations, data) => {
      if (!!data.id && (relations.a === data.id || relations.b === data.id)) {
        return;
      }
      const state = get();
      const a = relations.a
        ? state.element(relations.a)
        : null;
      const b = relations.b
        ? state.element(relations.b)
        : null;
      const element = data.id
        ? state.element(data.id)
        : data;
      const programs = state.value.data.programs.map((i) => {
        const rungs = i.rungs.map((i) => {
          const elements = dragRemove(element, i.elements);
          return { ...i, elements };
        }).map((i) => {
          if (i.id === relations.rung) {
            const elements = dragUpsert(element, i.elements, [a, b]);
            return { ...i, elements };
          }
          return i;
        });
        return { ...i, rungs };
      });
      state.setData({ programs });
    },
  }), {
    name: version,
    merge: (...args) => {
      const store = args[0] as State;
      const state = args[1] as State;
      const value = store.value;
      return { ...state, value };
    },
    storage: createJSONStorage(() => localStorage),
  }));

// to do...
export const cRecursion = (els: any[]): any[] => {
  return els.reduce((i, c) => {
    if ('elements' in c) {
      const a = cRecursion(c.elements[0]);
      const b = cRecursion(c.elements[1]);
      return [...i, ...a, ...b];
    }
    if (
      [
        ELEMENT_TYPES.CNTU,
        ELEMENT_TYPES.CNTD,
        // ELEMENT_TYPES.CNTR,
      ].includes(c.type)
    ) {
      i.push(c.id)
    }
    return i;
  }, []);
}

export const tRecursion = (els: any[]): any[] => {
  return els.reduce((i, c) => {
    if ('elements' in c) {
      const a = tRecursion(c.elements[0]);
      const b = tRecursion(c.elements[1]);
      return [...i, ...a, ...b];
    }
    if (
      [
        ELEMENT_TYPES.TON,
        ELEMENT_TYPES.TOF,
        // ELEMENT_TYPES.TRES,
      ].includes(c.type)
    ) {
      i.push(c.id)
    }
    return i;
  }, []);
}

export const TIMEOUT = 5000;
export const API_URL = `http://${window.location.host}`;

export const json = (data?: Data): object => {
  return toJSON(data); //to do...
}

export const jsonTransform = (data: object): Data => {
  return fromJSON(data); //to do...
}

const toType = (data: any) => {
  return Object.entries(data).reduce((a: any, c: any) => {
    a[c[0]] = isNaN(c[1]) ? c[1] : Number(c[1]);
    return a;
  }, {});
}

export const fromJSON = (obj: any): any => {
  const programs = obj.programs.map((program: any) => {
    if (!program.name) {
      throw new Error('Can\'t find program name. Program name is required.');
    }
    return {
      id: `${Math.floor(Math.random() * Date.now())}`,
      name: program?.name || '',
      description: program?.description || '',
      variables: Object.keys(program?.variables).reduce((acc: any[], cur: any) => {
        program.variables[cur].forEach((variable: any) => {
          acc.push({
            id: `${Math.floor(Math.random() * Date.now())}`,
            type: cur.toUpperCase(),
            name: variable.name,
            description: variable.description || '',
            value: variable.val,
            retain: variable.retain,
          });
        });
        return acc;
      }, []),
      rungs: program.rungs.map((rung: any) => {
        const elementMap = new Map();
        const counterMap = new Map();
        const connectionMap = new Map();

        rung.elements?.forEach((element: any) => {
          elementMap.set(element.id, element);
        });

        const collect = (d: any[], s: any[]=[], p: any=null): any[] => {
          if (!d.length) {
            return s;
          }
          if (d.length) {
            if (d.length > 1) {
              const element = elementMap.get(d[0]);

              const id = element.id;
              const type = element.type;
              const name = element.name;
              const description = element.description;
              const data = element.data || {};

              const parallelID = d.join('-'); //`${Math.floor(Math.random() * Date.now())}`;

              const counter = counterMap.get(id);
              if (counter) {
                counterMap.set(id, { c: counter.c+1, p: counter.p || parallelID, element });
              } else {
                counterMap.set(id, { c: 1, p: parallelID });
              }

              const f = { id, type, name, description, data };

              const a: any[] = [f, ...collect(element.conn, [], parallelID)];
              const b: any[] = collect(d.slice(1), [], parallelID);

              if (a.length && b.length) {
                const i = {
                  id: parallelID,
                  elements: [a, b],
                };
                s.push(i);

                const counter = counterMap.get(parallelID);
                if (counter) {
                  counterMap.set(parallelID, { c: counter.c+1, p: counter.p || p, element: i });
                } else {
                  counterMap.set(parallelID, { c: 1, p });
                }
              }     
              return s
            }
            const element = elementMap.get(d[0]);

            const id = element.id;
            const type = element.type;
            const name = element.name;
            const description = element.description;
            const data = element.data || {};

            const counter = counterMap.get(id);
            if (counter) {
              counterMap.set(id, { c: counter.c+1, p: counter.p || p, element });
            } else {
              counterMap.set(id, { c: 1, p });
            }

            const f = { id, type, name, description, data };

            s.push(f);
            return collect(element.conn, s, p);
          }
          return s;
        }

        const collectRemove = (d: any[]): any[] => {
          return d.reduce((i: any, c: any) => {
            const counter = counterMap.get(c.id);
            if (counter && counter.c > 1) {
              return i;
            }
            if ('elements' in c) {
              const a = collectRemove(c.elements[0]);
              const b = collectRemove(c.elements[1]);
              c.elements = [a, b];
              i.push(c);
              return i;
            }
            i.push(c);
            return i;
          }, []);
        }

        const collectUpdate = (d: any[]): any[] => {
          return d.reduce((i: any, c: any) => {
            if ('elements' in c) {
              const a = collectUpdate(c.elements[0]);
              const b = collectUpdate(c.elements[1]);
              c.elements = [a, b];
              i.push(c);
              const connection = connectionMap.get(c.id)
              if (connection) {
                i.push(connection);
              }
              return i;
            }
            i.push(c);
            return i;
          }, []);
        }

        const fix = (d: any[]): any[] => {
          return d.reduce((i: any, c: any) => {
            if ('elements' in c) {
              const a = fix(c.elements[0]);
              const b = fix(c.elements[1]);
              i.push({
                ...c,
                id: `${Math.floor(Math.random() * Date.now())}`,
                elements: [a, b],
              });
              return i;
            }
            i.push({
              ...c,
              id: `${Math.floor(Math.random() * Date.now())}`,
            });
            return i;
          }, []);
        }

        const rail = rung.elements[0];
        const data = collect(rail.conn);

        counterMap.forEach((value) => {
          if (value.c > 1) {
            connectionMap.set(value.p, value.element);
          }
        });

        return {
          id: `${Math.floor(Math.random() * Date.now())}`,
          name: rung.name || '',
          description: rung.description || '',
          elements: fix(collectUpdate(collectRemove(data))),
        }
      }),
    }
  }) || [];

  const tags = obj?.tags?.map((i: any) => ({
    id: `${Math.floor(Math.random() * Date.now())}`,
    address: i.addr,
    name: i.name,
    description: i.desc,
  })) || [];
  return {
    name: obj?.name || '',
    description: obj?.description || '',
    author: obj?.author || '',
    version: obj?.version || '',
    connection: obj?.connection || `http://${window.location.host}/ladder_program`,
    dataTime: obj?.dataTime || new Date().toISOString(),
    fwVersion: obj?.fwVersion || '',
    hwVersion: obj?.hwVersion || '',
    wifiIp: obj?.wifiIp || '',
    wifiSsid: obj?.wifiSsid || '',
    wifiMode: obj?.wifiMode || 'On',
    wifiStatus: obj?.wifiStatus || 'Connected',
    wifiPassword: obj?.wifiPassword  || '',
    ethernetIp: obj?.ethIp || '',
    ethernetMode: obj?.ethMode || 'On',
    ethernetStatus: obj?.ethStatus || 'Disconnected',
    tags,
    programs,
  };
}

export const toJSON = (data?: Data): any => {
  const rail = {
    id: 0,
    type: 'RAIL',
    name: 'Default Rail',
    description: '',
    data: {},
    conn: [],
  };

  const connectionsToJSON = (elements: any[], data: string[] = []): string[] => {
    return elements.reduce((acc, cur, i) => {
      if (i === 0 && 'elements' in cur) {
        const a = connectionsToJSON(cur.elements[0], acc);
        const b = connectionsToJSON(cur.elements[1], acc);
        return Array.from(new Set([...a, ...b]));
      }

      if (i === 0) {
        acc.push(cur.id);
      }
      return acc;
    }, data);
  }

  const recursionToJSON = (elements: any[], data=[], con: any[]=[]): any[] => {
    return elements.reduce((accumulator, element, i) => {
      const p = elements[i+1];

      let c: any[] = [];
      if (con.length) {
        c = con
      }
      if (p) {
        if ('elements' in p) {
          const a = connectionsToJSON(p.elements[0]);
          const b = connectionsToJSON(p.elements[1]);
          c = [...a, ...b];
        } else {
          c = [p.id];
        }
      }

      if ('elements' in element) {
        recursionToJSON(element.elements[0], accumulator, c);
        recursionToJSON(element.elements[1], accumulator, c);

        return accumulator;
      }

      accumulator.push({
        id: element.id,
        type: element.type,
        name: element.name,
        description: element.description,
        data: element.data ? toType(element.data) : {},
        conn: c,
      });
      return accumulator;
    }, data);
  }

  const programs = data?.programs?.map((program) => ({
    name: program?.name || '',
    description: program?.description || '',
    variables: program.variables.reduce((accumulator, variable) => {
      const type = variable.type.toLowerCase();
      if (!accumulator[type]) {
        accumulator[type] = [];
      }
      accumulator[type].push({
        name: variable.name,
        description: variable.description || '',
        val: variable.value,
        retain: variable.retain,
      });
      return accumulator;
    }, {} as any),
    rungs: program.rungs.map((rung) => {
      const e = recursionToJSON([rail, ...rung.elements]);
      const id = new Map(e.map((c, i) => [c.id, i]));
      return {
        name: rung.name || '',
        description: rung.description || '',
        elements: e.map((i) => {
          i.id = id.get(i.id);
          i.conn = i.conn.map((i: any) => id.get(i));
          return i;
        }),
      };
    }),
  })) || [];

  const tags = data?.tags?.map((i: any) => ({
    addr: i.address,
    name: i.name,
    desc: i.description,
  })) || [];

  return {
    name: data?.name || '',
    description: data?.description || '',
    connection: data?.connection || `http://${window.location.host}/ladder_program`,
    dataTime: data?.dataTime || new Date().toISOString(),
    author: data?.author || '',
    version: data?.version || '',
    fwVersion: data?.fwVersion || '',
    hwVersion: data?.hwVersion || '',
    wifiIp: data?.wifiIp || '',
    wifiSsid: data?.wifiSsid || '',
    wifiMode: data?.wifiMode || 'On',
    wifiStatus: data?.wifiStatus || 'Connected',
    wifiPassword: data?.wifiPassword  || '',
    ethIp: data?.ethernetIp || '',
    ethMode: data?.ethernetMode || 'On',
    ethStatus: data?.ethernetStatus || 'Disconnected',
    tags,
    programs,
  };
}

export const WIRE_WIDTH = 45;
export const WIRE_HEIGHT = 47;
export const WIRE_X = 2;
export const WIRE_Y = -8;

export const ELEMENT_WIDTH = 45;
export const ELEMENT_HEIGHT = 45;

export enum TYPES {
  WIRE = 'wire',
  ELEMENT = 'element',
  PARALLEL = 'parallel',
}

interface Options {
  width: number | undefined;
  height: number | undefined;
}

interface Size {
  width: number;
  height: number;
}

export interface DragObject {
  id: string;
  name: string;
  type: ELEMENT_TYPES
};

export interface DropResult {
  isOver: boolean;
  canDrop: boolean;
}

const count = (data: any[], i=0): number => {
  return data.reduce((acc, c) => {
    if ('elements' in c) {
      const a = count(c.elements[0]);
      const b = count(c.elements[1]);
      acc+=2;
      return acc+(a > b ? a : b);
    }
    if ([
      ELEMENT_TYPES.TON,
      ELEMENT_TYPES.TOF,
      ELEMENT_TYPES.MOV,
      ELEMENT_TYPES.CNTU,
      ELEMENT_TYPES.CNTD,
      ELEMENT_TYPES.ADD,
      ELEMENT_TYPES.SUB,
      ELEMENT_TYPES.MUL,
      ELEMENT_TYPES.DIV,
    ].includes(c.type)) {
      acc+=1.5;
      return acc;
    }
    acc+=1;
    return acc;
  }, i);
}

const calculateData = (id: string, elements: Array<Parallel<Element>>, root=true, l=true): Array<any> => {
  const items = elements.reduce<Array<any>>((accumulator, current, index) => {
    const e = accumulator[accumulator.length-1];

    if ('elements' in current) {
      const ac = count(current.elements[0]);
      const bc = count(current.elements[1]);

      const a = calculateData(id, current.elements[0], false, ac >= bc ? false : true);
      const b = calculateData(id, current.elements[1], false, bc >= ac ? false : true);

      if (root || e) {
        accumulator.push({
          rung: id,
          type: TYPES.WIRE,
          data: [null, current.id],
        });
      }
      accumulator.push({
        type: TYPES.PARALLEL,
        data: [a, b],
        id: current.id,
      });
      return accumulator
    }
    accumulator.push({
      rung: id,
      type: TYPES.WIRE,
      data: [null, current.id],
    });
    accumulator.push({
      id: current.id,
      type: TYPES.ELEMENT,
      data: current.type,
    })

    return accumulator;
  }, []);

  const item = items[items.length-1];
  //parallel nested and nest exist ??? try this
  // if (!l && item.type === TYPES.PARALLEL) {
  //   return items
  // }

  const rung = id;
  const type = TYPES.WIRE;
  const data = item && 'id' in item ? [item.id, null] : [];
  items.push({ rung, type, data });
  return items;
}

const calculateDataSize = (items: Array<any>): Array<any> => {
  return items.reduce<Array<any>>((accumulator, current) => {
    if (current.type === TYPES.PARALLEL) {
      const a = calculateDataSize(current.data[0]);
      const b = calculateDataSize(current.data[1]);

      const aw = a.reduce((i, c) => i+c.width, 0);
      const bw = b.reduce((i, c) => i+c.width, 0);

      const ah = a.reduce((i, c) => i > c.height ? i : c.height, 0);
      const bh = b.reduce((i, c) => i > c.height ? i : c.height, 0);
      
      accumulator.push({
        ...current,
        data: [a, b],
        width: aw > bw ? aw : bw, 
        height: ah+bh,
        offset: bh,
      });
      return accumulator;
    }
    if (current.type === TYPES.ELEMENT && [
      ELEMENT_TYPES.TON,
      ELEMENT_TYPES.TOF,
      ELEMENT_TYPES.MOV,
    ].includes(current.data)) {
      accumulator.push({
        ...current,
        width: 90, 
        height: 64, //45 -> 19
      })
      return accumulator;
    }
    if (current.type === TYPES.ELEMENT && [
      ELEMENT_TYPES.CNTU,
      ELEMENT_TYPES.CNTD,
      ELEMENT_TYPES.ADD,
      ELEMENT_TYPES.SUB,
      ELEMENT_TYPES.MUL,
      ELEMENT_TYPES.DIV,
    ].includes(current.data)) {
      accumulator.push({
        ...current,
        width: 90, 
        height: 82, //45m->37
      });
      return accumulator;
    }
    if (current.type === TYPES.ELEMENT) {
      accumulator.push({
        ...current,
        width: ELEMENT_WIDTH,
        height: ELEMENT_HEIGHT,
      })
    }
    if (current.type === TYPES.WIRE) {
      accumulator.push({
        ...current,
        width: WIRE_WIDTH,
        height: WIRE_HEIGHT,
      })
    }
    return accumulator;
  }, []);
}

const calculateDataCoordinate = (items: any[], x = 0, y = 0): Array<any> => {
  return items.reduce<any[]>((accumulator, current) => {
    const item = accumulator[accumulator.length-1];
    if (current.type === TYPES.PARALLEL) {
      const height = current.data[0].filter((i: any) =>i.type !== TYPES.WIRE).reduce((h: any, e: any) => Math.max(h, e.height), 0);

      const a = calculateDataCoordinate(current.data[0], 0, 0);
      const b = calculateDataCoordinate(current.data[1], 0, height);
      
      accumulator.push({
        ...current,
        data: [a, b],
        x: item ? item.width + item.x-2 : x,
        y: y, 
      });
      return accumulator;
    }
    if (current.type === TYPES.ELEMENT) {
      accumulator.push({
        ...current,
        x: item ? item.width + item.x-2 : x,
        y: y+2, 
      })
    }
    if (current.type === TYPES.WIRE) {
      accumulator.push({
        ...current,
        x: item ? item.width + item.x : x,
        y: y+WIRE_Y, 
      })
    }
    return accumulator;
  }, []);
}

export const transform = (id: string, elements: Array<Parallel<Element>>, options: Options): Array<any> => {
  const data = calculateData(id, elements);
  // console.log('-'.repeat(100))
  const dataSize = calculateDataSize(data);
  const dataCoordinate = calculateDataCoordinate(dataSize);

  const correct = (items: any[], options: Options): any[] => {
    return items.reduce((accumulator, current, index) => {
      if (current.type === TYPES.PARALLEL) {
        const width = current.width;
        const height = current.height;
        const a = correct(current.data[0], { width, height });
        const b = correct(current.data[1], { width, height });
        accumulator.push({
          ...current,
          data: [a, b],
        })
        return accumulator;
      }
      if (index === items.length-1) {
        const w = options.width || 0;
        const difference = w-current.x;
        const width = current.x > w ? WIRE_WIDTH : (difference > WIRE_WIDTH ? difference : WIRE_WIDTH);
        
        accumulator.push({ ...current, width });
        return accumulator;
      }
      accumulator.push(current);
      return accumulator;
    }, []);
  }
  return correct(dataCoordinate, options);
}

export const transformSize = (data: Array<any>, options: Options): Size => {
  const e = data[data.length-1];
  const width = e ? e.width+e.x : 0;

  const height = data.reduce((accumulator, current) => {
    return accumulator > current.height ? accumulator : current.height;
  }, options.height || WIRE_HEIGHT);

  return { width, height };
}
