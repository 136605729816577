import { Box, Collapse, IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import { FC, Fragment, useEffect, useState } from 'react';
import { Add, ArrowDropDown, ArrowDropUp, Assignment, ClearOutlined, Close, DeleteOutlineOutlined, DoneOutlined, EditOutlined } from '@mui/icons-material';
import { DataGrid, GridColDef, GridActionsCellItem, GridRowModes, GridRowModesModel, GridRowParams } from '@mui/x-data-grid';
import { useTranslation } from '../translation/translation';
import { Modal } from '../modal/modal';
import { KEYS, Tag, useStore } from '../store/store';

export const Tags: FC= () => {
  const store = useStore();
  const [t] = useTranslation();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState<Array<Tag>>([]);
  const [loading, setLoading] = useState(true);

  const r = store.tags();
  useEffect(() => {
    setRows(r);
    setLoading(false);
  }, [r]);

  const columns: Array<GridColDef> = [{
    field: 'id',
  }, {
    field: 'name',
    headerName: t('Name'),
    editable: true,
    sortable: false,
    minWidth: 120,
  }, {
    field: 'address',
    headerName: t('Address'),
    editable: true,
    sortable: false,
    minWidth: 150,
  }, {
    field: 'description',
    headerName: t('Description'),
    editable: true,
    sortable: false,
    flex: 1,
  }, {
    field: 'actions',
    type: 'actions',
    align: 'right',
    headerAlign: 'right',
    minWidth: 88,
    renderHeader: () => {
      return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <IconButton size="small" onClick={(ev) => {
            ev.stopPropagation();

            const id = `${Math.floor(Math.random() * Date.now())}`;
            const name = '';
            const description = '';
            const address = '';
            const isNew = true;
            setRows([{ id, name, description, address, isNew } as any, ...rows]);

            const mode = GridRowModes.Edit;
            const fieldToFocus = 'name';
            setRowModesModel({ ...rowModesModel, [id]: { mode, fieldToFocus } });
          }}>
            <Add fontSize="small" />
          </IconButton>
        </div>
      )
    },
    getActions: (params: GridRowParams) => {
      const id = params.id.toString();
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<DoneOutlined />}
            label={t('Save')}
            onClick={() => {
              const mode = GridRowModes.View;
              setRowModesModel({ ...rowModesModel, [id]: { mode } });
            }}
          />,
          <GridActionsCellItem
            icon={<ClearOutlined />}
            label={t('Cancel')}
            onClick={() => {
              setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
              });
              const editedRow: any = rows.find((row) => row.id === id);
              if (editedRow && editedRow.isNew) {
                setRows(rows.filter((row) => row.id !== id));
              }
            }}
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditOutlined />}
          label={t('Edit')}
          onClick={() => {
            const mode = GridRowModes.Edit;
            setRowModesModel({ ...rowModesModel, [id]: { mode } });
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlineOutlined />}
          label={t('Delete')}
          onClick={() => {
            store.tagRemove(id);
          }}
        />,
      ];
    }
  }];
  return (
    <Fragment>
      <Tooltip title={t('Tags')} arrow>
        <IconButton onClick={(ev) => {
          ev.stopPropagation();
          store.dialogChange(KEYS.TAGS);
        }}>
          <Assignment color={store.dialog(KEYS.TAGS) ? 'primary' : 'inherit'} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Modal style={{ pointerEvents: 'auto', minWidth: '720px' }} id={KEYS.TAGS} open={!!store.dialog(KEYS.TAGS)}>
        <List sx={{ padding: '0px' }}>
          <ListItem sx={{ padding: '8px 8px 8px 12px' }}>
            <ListItemText primary={t('Tags')} primaryTypographyProps={{ color: 'primary', variant: 'button' }} />
            <Box>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.collapseChange(KEYS.TAGS);
              }}>
                {!store.collapse(KEYS.TAGS)
                  ? <ArrowDropDown fontSize="small" />
                  : <ArrowDropUp fontSize="small" />}
              </IconButton>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.dialogChange(KEYS.TAGS);
              }}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </ListItem>
          <Collapse className="MuiDialogContent-collapse" in={!store.collapse(KEYS.TAGS)} timeout="auto">
            <Box sx={{
              height: '318px',
            }}>
              <DataGrid
                disableColumnMenu
                loading={loading}
                editMode="row"
                localeText={{
                  noRowsLabel: t('No rows'),
                  noResultsOverlayLabel: t('No results found.'),
                  MuiTablePagination: {
                    labelRowsPerPage: t('Rows per page:'),
                    labelDisplayedRows: ({ from, to, count }) => 
                      `${from}–${to} ${t('of')} ${count !== -1 ? count : `${t('more than')} ${to}`}`,
                  }
                }}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[10, 25, 50]}
                columnVisibilityModel={{
                  id: false,
                }}
                rowModesModel={rowModesModel}
                processRowUpdate={(newRow: any) => {
                  const updatedRow = { ...newRow, isNew: false };
                  newRow.isNew ? store.tagCreate(updatedRow) : store.tagUpdate(newRow.id, updatedRow);
                  return updatedRow;
                }}
                onRowModesModelChange={setRowModesModel}
                onRowEditStart={(params, ev) => {
                  ev.stopPropagation();
                  ev.defaultMuiPrevented = false;
                }}
                onRowEditStop={(params, ev) => {
                  ev.defaultMuiPrevented = true;
                }}
                rows={rows}
                columns={columns}
              />
            </Box>  
          </Collapse>
        </List>
      </Modal>
    </Fragment>
  )
}