import { Upload } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC, ChangeEvent, useState, useRef } from 'react';
import { useTranslation } from '../translation/translation';
import { jsonTransform, useStore } from '../store/store';

export const JsonFileSet: FC = () => {
  const store = useStore();
  const ref = useRef<HTMLInputElement>(null);
  const [t] = useTranslation();
  const [disabled, setDisabled] = useState(false);
  
  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setDisabled(true);
    
    const file = ev?.target?.files?.[0];

    if (!file) {
      store.notification({
        type: 'error',
        text: 'File Not Found.',
      });
      setDisabled(false);
      return;
    }

    if (!file.name.includes('.json')) {
      store.notification({
        type: 'error',
        text: "Can't parse file. Please choose correct JSON file.",
      });
      setDisabled(false);
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsText(file, 'UTF-8');

    if (ref.current) {
      ref.current.value = '';
    }

    fileReader.addEventListener('error', () => {
      store.notification({
        type: 'error',
        text: 'Error occurred reading file.',
      });
      setDisabled(false);
    });

    fileReader.addEventListener('load', (ev) => {
      try {
        const json = JSON.parse(ev?.target?.result as string);
        const data = jsonTransform(json);

        store.notification({
          type: 'success',
          text: t('File: {1} read successfully').replace('{1}', file.name),
        });

        store.setData(data);

        setDisabled(false);
      } catch (err: any) {
        store.notification({
          type: 'error',
          text: err?.message,
        });
        setDisabled(false);
      }
    });
  }

  return (
    <IconButton size="small" component="label" disabled={disabled}>
      <input ref={ref} hidden accept=".json" type="file" onInput={onChange} />
      <Upload fontSize="small" />
    </IconButton>
  )
}
