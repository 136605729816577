import { Autocomplete, Box, CircularProgress, Collapse, IconButton, List, ListItem, ListItemText, TextField, Tooltip } from '@mui/material';
import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { grey } from '@mui/material/colors';
import { ArrowDropDown, ArrowDropUp, Close, DownloadOutlined, HighlightOffOutlined, SearchOutlined, TerminalTwoTone } from '@mui/icons-material';
import { useTranslation } from '../translation/translation';
import { API_URL, KEYS, THEMES, TIMEOUT, useStore } from '../store/store';
import { Modal } from '../modal/modal';

interface Data {
  id: number;
  text: string; 
  html?: string;
}

export const Logs: FC = () => {
  const [color, setColor] = useState<'primary' | 'error'>('primary');
  const [data, setData] = useState<Data[]>([]);
  const [search, setSearch] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [t] = useTranslation();
  const ref = useRef<HTMLParagraphElement>(null);
  const store = useStore();
  const theme = store.theme();

  const isDialog = store.dialog(KEYS.LOGS);
  useEffect(() => {
    if (isDialog) {
      const onOpen = (ev: EventSourceEventMap['open']) => {
        setData([]);
        setColor('primary');
      }
      const onError = (ev: EventSourceEventMap['error']) => {
        console.error(ev);
        setColor('error');
      }
      const onMessage = (ev: EventSourceEventMap['message']) => {
        const text = ev.data.toString();
        setData((data) => [...data, { id: data.length+1, text }]);
      }

      const eventSource = new EventSource(`${API_URL}/logs`);
      eventSource.addEventListener('error', onError);
      eventSource.addEventListener('open', onOpen);
      eventSource.addEventListener('message', onMessage);
      return () => {
        eventSource.removeEventListener('open', onOpen);
        eventSource.removeEventListener('error', onError);
        eventSource.removeEventListener('message', onMessage);
        eventSource.close();
      };
    }
  // eslint-disable-next-line
  }, [isDialog]);

  useEffect(() => {
    if (options.length) {
      return;
    }
    const timeout = setTimeout(() => ref.current?.scrollIntoView(), 1);
    return () => clearTimeout(timeout);
  }, [options, data, ref])

  const filterData = useCallback((i: Data) => {
    if (!options.length) {
      return i;
    }
    return new RegExp(options.map((i => `(?=.*${i})`)).join(''), 'gi').test(i.text);
  }, [options]);

  const mapData = useCallback((i: Data) => {
    if (!options.length) {
      delete i.html;
      return i;
    }
    return options.reduce((a, c) => {
      a.html = i.text.replace(new RegExp(`(${c})`, 'gi'), '<span class="highlight">$1</span>');
      return a;
    }, { ...i, html: '' });
  }, [options]);

  return (
    <Fragment>
      <Tooltip title={t('Logs')} arrow>
        <IconButton onClick={(ev) => {
          ev.stopPropagation();
          store.dialogChange(KEYS.LOGS);

          const token = store.token();
          const method = 'POST';
          const signal = AbortSignal.timeout(TIMEOUT);
          const headers = { 'Authorization': `Basic ${token}` };
          const url = `${API_URL}/logs`;
          const body = JSON.stringify({ enable: true });
          fetch(url, { method, signal, headers, body }).catch(console.error);
        }}>
          <TerminalTwoTone color={store.dialog(KEYS.LOGS) ? 'primary' : 'inherit'} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Modal
        style={{ pointerEvents: 'auto', minWidth: '720px' }} 
        id={KEYS.LOGS}
        open={!!store.dialog(KEYS.LOGS)}
      >
        <List sx={{ padding: '0px', overflow: 'hidden' }}>
          <ListItem sx={{ padding: '8px 8px 8px 12px' }}>
            <ListItemText primary={t('Logs')} primaryTypographyProps={{ color: 'primary', variant: 'button' }} sx={{ flex: 'none' }} />
            <Box sx={{
              display: 'flex',
              flex: 1,
            }}>
              <Box sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                padding: '0 8px 0 8px',
              }}>
                <IconButton size="small" onClick={() => {
                  setSearch(!search);
                  setOptions(() => []);
                }}>
                  <SearchOutlined fontSize="small" color={options.length ? 'primary' : 'inherit'} />
                </IconButton>
                <Autocomplete
                  freeSolo
                  multiple
                  disableClearable
                  size="small"
                  clearIcon={<Close fontSize='small' sx={{ marginRight: '2px' }} />}
                  sx={{
                    width: search ? '100%' : '0px',
                    opacity: search ? '1' : '0',
                    transition: 'width 0.2s ease-in-out, opacity 0.2s ease-in-out',
                  }}
                  value={options}
                  options={[]}
                  defaultValue={[]}
                  onChange={(ev, items) => {
                    if (items.length > 3) {
                      items.shift(); 
                    }
                    setOptions([...items]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="standard"
                      placeholder={options.length ? '' : 'Type to search...'}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </Box>
              <IconButton size="small" onClick={(ev) => {
                const blob = new Blob([data.map((i) => i.text).join('/n')],{ type:'text/plain;charset=utf-8;' });
                const href = URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.href = href;
                link.download = `${Date.now()}-logs.txt`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}>
                <DownloadOutlined fontSize="small"/>
              </IconButton>
              <IconButton disabled={!data.length} size="small" onClick={(ev) => {
                ev.stopPropagation();
                setData([]);
              }}>
                <HighlightOffOutlined fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.collapseChange(KEYS.LOGS);
              }}>
                {!store.collapse(KEYS.LOGS)
                  ? <ArrowDropDown fontSize="small" />
                  : <ArrowDropUp fontSize="small" />}
              </IconButton>
              <IconButton size="small" onClick={(ev) => {
                ev.stopPropagation();
                store.dialogChange(KEYS.LOGS);

                const token = store.token();
                const method = 'POST';
                const signal = AbortSignal.timeout(TIMEOUT);
                const headers = { 'Authorization': `Basic ${token}` };
                const url = `${API_URL}/logs`;
                const body = JSON.stringify({ enable: false });
                fetch(url, { method, signal, headers, body }).catch(console.error);
              }}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </ListItem>
          
          <Collapse className="MuiDialogContent-collapse" in={!store.collapse(KEYS.LOGS)} timeout="auto">
            <Box sx={{
              overflow: 'scroll',
              height: '200px',
              padding: '8px 12px 4px 12px',
              fontSize: '11px',
              fontFamily: '"Lucida Console", "Courier New", monospace',
              color: theme === THEMES.LIGHT ? grey[900] : grey[50],
              '& p': {
                margin: '4px 0px'
              },
              '& p:nth-of-type(1)': {
                marginTop: '0px',
              },
              display: 'flex',
              justifyContent: 'end',
              flexDirection: 'column',
            }}>
              <Box>
                {data.filter(filterData).map(mapData).map((i, key) => (
                  <p key={key}><span>{'>'}</span> {i.html ? (<span dangerouslySetInnerHTML={{__html: i.html}} />) : i.text}</p>
                ))}
              </Box>
              <p ref={ref} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
                <span className="blink">{'>'}</span>
                <CircularProgress color={color} size={12} sx={{ marginRight: '6px' }}/>
              </p>
            </Box>
          </Collapse>
        </List>
      </Modal>
    </Fragment>
  );
}