import { Avatar, Box, ListItemAvatar, ListItemButton, ListItemText, Tooltip, useTheme } from '@mui/material';
import { FC, createElement } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from '../translation/translation';
import { ELEMENT_TYPES, PROGRAM_ELEMENTS } from '../store/store';

interface Props {
  name: string;
  type: ELEMENT_TYPES;
}

export const Instruction: FC<Props> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const name = props.name;
  const type = props.type

  const [dragParams, drag] = useDrag(() => ({
    type: 'box',
    item: { type, name },
    collect: (monitor: any) => ({
      dragging: monitor.isDragging(),
      clientOffset: monitor.getSourceClientOffset(),
    }),
  }), [props]);

  const dragging = dragParams.dragging;
  const color = theme.palette.text.primary;

  return (
    <ListItemButton ref={drag} sx={{ padding: '8px 16px', cursor: dragging ? 'grabbing' : 'grab' }}>
      <Tooltip title={props.name} arrow>
        <ListItemAvatar sx={{ minWidth: '37px' }}>
          <Avatar variant="square" sx={{ bgcolor: 'transparent', width: 20, height: 20 }}>
            <Box sx={{ transform: 'scale(0.6)' }}>
              {createElement(PROGRAM_ELEMENTS[type], { color })}
            </Box>
          </Avatar>
        </ListItemAvatar>
      </Tooltip>
      <ListItemText
        sx={{ margin: '0px' }}
        primaryTypographyProps={{ variant: 'button', style: { fontSize: '12px', lineHeight: 1.4 } }}
        primary={props.type}
        secondaryTypographyProps={{ variant: 'caption', style: { lineHeight: 1.3 } }}
        secondary={t(props.name)}
      />
    </ListItemButton>
  )
}
